@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.cards-skeleton {
  width: 100%;
  border: variables.$standard-border;
  padding: utils.toRem(16px);
  margin: 0 auto;
  margin-bottom: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    display: flex;
    align-items: center;
  }

  &__text-wrapper {
    flex-grow: 4;
  }

  &__button {
    flex-grow: 1;
    margin-top: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-top: 0;
    }
  }

  &__text {
    height: utils.toRem(22px);
    background-color: colors.$surface-color-stroke;

    &--small {
      width: math.percentage(math.div(116px, 416px));
    }

    &--medium {
      width: math.percentage(math.div(166px, 416px));
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(8px);
    }
  }

  &__inner-button {
    background-color: colors.$surface-color-stroke;
    height: utils.toRem(38px);
  }
}
