@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  height: utils.toRem(16px);
  width: 100%;
  background-color: colors.$skeleton-mask;

  &--left {
    max-width: utils.toRem(160px);
  }

  &--right {
    max-width: utils.toRem(92px);
  }

  &__row {
    border-bottom: variables.$standard-border;

    &:last-child {
      border-bottom: none;
    }
  }

  &__cell {
    padding: utils.toRem(18px) utils.toRem(12px);

    &:first-child {
      @include breakpoints.query('<lg') {
        padding-left: utils.toRem(24px);
      }
    }

    &:last-child {
      @include breakpoints.query('<lg') {
        padding-right: utils.toRem(24px);
      }
    }

    @include breakpoints.query('>=lg') {
      padding: utils.toRem(18px) 0;
    }
  }

  &__row:last-child &__cell {
    @include breakpoints.query('>=lg') {
      padding-bottom: utils.toRem(8px);
    }
  }
}
