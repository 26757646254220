@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-Thin.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-Thin.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-ThinItalic.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-ThinItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-Light.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-Medium.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-Bold.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('./../../fonts/HelveticaNeue-BoldItalic.woff2') format('woff2'),
    url('./../../fonts/HelveticaNeue-BoldItalic.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
