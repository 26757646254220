@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/animations';

.invite-user {
  &__modal {
    overflow-y: visible;
  }

  &__modal-header {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
    margin: utils.toRem(8px) 0 utils.toRem(16px);
  }

  &__modal-footer {
    bottom: auto;
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }
}

.form-wrapper {
  width: 100%;
  display: block;
}
