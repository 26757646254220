@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.purchase-table {
  padding-top: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(24px);
  }

  &__header-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: utils.toRem(24px);

    &--en {
      @include breakpoints.query('>=lg') {
        grid-template-columns: 1.5fr 0.5fr;
      }
    }
  }

  &__download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: utils.toRem(24px);

    &--en {
      @include breakpoints.query('>=lg') {
        margin-top: 0;
        align-items: flex-end;
      }
    }
  }

  &__download-title {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__download-button {
    @include typekit.font-body-1-bold;
  }

  &__legend-wrapper {
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: 0;
      margin-right: utils.toRem(16px);
    }
  }

  &__table-wrapper {
    margin-bottom: utils.toRem(40px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(48px);
    }
  }

  &__pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
