@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/buttons';
@use 'src/assets/styles/base/typekit';

.my-account {
  position: relative;
  height: 100%;

  &__toggle {
    height: 100%;
    padding: utils.toRem(20px) utils.toRem(16px);
    display: flex;
    align-items: center;
    @include buttons.nav-link('--open');
  }

  &__avatar-wrapper {
    margin-right: utils.toRem(16px);
  }

  &__text {
    @include typekit.font-subtitle-1;
    display: inline-block;
    margin-right: utils.toRem(8px);
    color: colors.$surface-color-active;
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);

    &--open {
      transform: rotate(180deg);
    }
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: variables.$standard-border;
    background-color: colors.$white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

    &--hidden {
      display: none;
    }
  }

  &__link {
    @include typekit.font-subtitle-1;
    display: flex;
    justify-content: space-between;
    text-align: start;
    align-items: center;
    width: 100%;
    padding: utils.toRem(16px);
    color: colors.$surface-color-active;
    @include buttons.nav-link();

    &:not(:last-child) {
      border-bottom: variables.$standard-border;
    }
  }

  &__bold {
    font-weight: 700;
  }

  &__logout-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
    fill-opacity: 1;
  }
}
