@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.user-permissions {
  display: flex;
  height: inherit;
  align-items: flex-start;
  flex-direction: column;

  &__subheading {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
  }

  &__user-description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-top: utils.toRem(8px);
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__invite {
    margin-bottom: utils.toRem(24px);
    width: 100%;

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(32px);
      width: auto;
    }
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }
}

.invited-user {
  width: 100%;
  height: auto;
  padding: utils.toRem(16px);
  border: variables.$standard-border;
  margin-bottom: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: utils.toRem(729px);
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;

    &--name {
      @include typekit.font-subtitle-1;
      color: colors.$surface-color-active;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include breakpoints.query('>=md') {
        max-width: utils.toRem(380px);
      }
    }

    &--email {
      @include typekit.font-body-1;
      color: colors.$surface-color-inactive-med;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.invite-button {
  width: 100%;
  margin-top: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    width: auto;
    margin-top: 0;
  }
}

.form-dropdown {
  width: 100%;
  margin-top: utils.toRem(15px);

  @include breakpoints.query('>=md') {
    margin-top: 0;
    width: utils.toRem(288px);
  }
}

.options-footer {
  width: 100%;
  height: utils.toRem(56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  &__separator {
    border-top: 1px solid colors.$surface-color-inactive-low;
    flex: 0 0 auto;
  }

  &__content {
    padding: 0 utils.toRem(16px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;
  }

  &__label {
    display: block;
    color: colors.$danger-color-700;
    @include typekit.font-subtitle-1-bold;
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$danger-color-700;
  }
}

.modal {
  &__subtitle {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    margin-top: utils.toRem(24px);
  }

  &__text {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(24px);
  }
}