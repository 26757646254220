@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.breakdown-section {
  @include layout.container-md;
  padding-top: utils.toRem(32px);
  padding-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(48px);
    padding-bottom: utils.toRem(58px);
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(577px);
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoints.query('>=md') {
      padding-top: utils.toRem(50px);
      flex-direction: row;
      align-items: center;
    }
  }

  &__overline {
    @include typekit.font-overline;
    margin-bottom: utils.toRem(24px);
    color: colors.$surface-color-inactive-med;
  }

  &__chart-container {
    display: flex;
    justify-content: center;
    padding: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      padding: 0;
      flex-grow: 1;
    }
  }

  &__chart-wrapper {
    max-width: utils.toRem(240px);

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(300px);
    }
  }

  &__cards-container {
    @include breakpoints.query('>=md') {
      width: math.percentage(math.div(680px, 1120px));
    }
  }

  &__cards-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      grid-template-columns: 1fr 1fr;
      column-gap: utils.toRem(40px);
    }
  }

  &__spinner-wrapper {
    padding-top: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      padding-top: utils.toRem(136px);
      padding-bottom: utils.toRem(86px);
    }
  }
}
