.sr-only {
  position: absolute;
  height: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  overflow: hidden;
  opacity: 0;
}

.sr-only-focus-visible {
  &:focus {
    width: auto;
    height: auto;
    opacity: 1;
    position: relative;
  }
}
