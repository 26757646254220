@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.filter {
  @include breakpoints.query('>=md') {
    position: relative;
  }

  &__button {
    @include typekit.font-body-2;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: utils.toRem(6px) utils.toRem(10px);
    color: colors.$secondary-color-900;
    background-color: colors.$white;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: variables.$standard-border;
      border-radius: utils.toRem(4px);
      transition: border-color 300ms ease-in-out, color 300ms ease-in-out;
    }

    &:hover {
      color: colors.$primary-color-400;
      background-color: colors.$primary-color-state-01;

      &::before {
        border-color: colors.$primary-color-state-03;
      }
    }

    &:focus-within {
      outline: none;
      background-color: colors.$primary-color-state-01;
      color: colors.$primary-color-400;

      &::before {
        border-width: 4px;
        border-color: colors.$secondary-color-400;
      }
    }

    &:active {
      color: colors.$primary-color-400;
      background-color: colors.$primary-color-state-02;

      &::before {
        border-width: 1px;
        border-color: colors.$primary-color-state-02;
      }
    }

    &--active {
      color: colors.$primary-color-400;
      background-color: colors.$primary-color-state-01;

      &::before {
        border-width: 1px;
        border-color: colors.$primary-color-400;
      }
    }

    &:disabled {
      pointer-events: none;
      color: colors.$disabled-color;

      &::before {
        border-color: colors.$disabled-color;
      }
    }

    &--left {
      flex-direction: row-reverse;
    }
  }

  &__icon {
    width: utils.toRem(20px);
    height: utils.toRem(20px);
    fill: currentColor;
    transform: rotate(0deg);
    margin-left: utils.toRem(4px);

    &--active {
      transform: rotate(180deg);
    }

    &--left {
      flex-direction: row-reverse;
      margin-left: 0;
      margin-right: utils.toRem(4px);
    }
  }

  &__content-wrapper {
    @include breakpoints.query('>=lg') {
      display: block;
      position: absolute;
      z-index: variables.$z-index-modal-content;
      max-width: utils.toRem(727px);
      width: auto;
      height: auto;
      max-height: utils.toRem(390px);
      overflow-y: scroll;
      top: 100%;
      margin-top: utils.toRem(8px);
      background-color: colors.$white;
      border: variables.$standard-border;
    }

    &--auto-height {
      @include breakpoints.query('>=lg') {
        max-height: none;
      }
    }
  }

  &__content {
    flex-grow: 1;
    padding: utils.toRem(16px);
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include breakpoints.query('>=md') {
      padding: 0;
    }
  }
}

.modal {
  height: 100%;
  width: 100%;
  max-height: 100%;

  @include breakpoints.query('>=md') {
    border-radius: 0px;
  }

  &__close-button {
    fill: colors.$surface-color-inactive-med;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }

  &__title {
    @include typekit.font-subtitle-1;
    color: colors.$secondary-color-900;
  }
}

.modal-footer {
  &:before {
    content: '';
    display: block;
    width: calc(100% + #{utils.toRem(32px)});
    position: absolute;
    top: 0;
    left: utils.toRem(-16px);
    height: 1px;
    background-color: colors.$surface-color-inactive-low;
  }

  @include breakpoints.query('>=md') {
    flex-direction: column-reverse;
  }

  @include breakpoints.query('>=lg') {
    flex-direction: row;

    &:before {
      display: none;
    }
  }

  &__text-link {
    margin-top: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(8px);
      margin-right: 0;
    }

    @include breakpoints.query('>=lg') {
      margin-top: 0;
      margin-right: utils.toRem(8px);
    }
  }
}
