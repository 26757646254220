@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.local-team-widget {
  height: fit-content;
  background-color: colors.$white;

  &__text-wrapper {
    padding: utils.toRem(12px) utils.toRem(16px);
  }

  &__title {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
  }

  &__list-item {
    border-top: variables.$standard-border;
  }
}
