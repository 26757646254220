@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.calculator-banner {
  padding: utils.toRem(24px) utils.toRem(16px);
  background-color: colors.$secondary-color-700;
  color: colors.$white;
  overflow: hidden;

  @include breakpoints.query('>=md') {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding: utils.toRem(40px) 0 utils.toRem(40px) utils.toRem(80px);
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      width: math.percentage(math.div(415px, 1040px));
    }
  }

  &__image {
    width: math.percentage(math.div(327px, 343px));

    @include breakpoints.query('>=md') {
      width: 100%;
      margin-right: utils.toRem(-52px);
    }
  }

  &__text-wrapper {
    @include breakpoints.query('>=md') {
      width: 50%;
      max-width: utils.toRem(492px);
    }
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__description {
    @include typekit.font-body-2;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__list {
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(48px);
    }
  }

  &__list-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__icon-wrapper {
    display: inline-block;
    margin-right: utils.toRem(16px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$white;
  }

  &__icon-text {
    @include typekit.font-body-2;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
      margin-bottom: utils.toRem(16px);
    }
  }
}
