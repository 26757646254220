@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.pre-order {
  width: 100%;
  padding-block: utils.toRem(24px) utils.toRem(40px);

  @include breakpoints.query('>=md') {
    padding-block: utils.toRem(48px) utils.toRem(80px);
  }

  &__container {
    @include layout.container-md;
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__groups {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      gap: utils.toRem(40px);
      margin-bottom: utils.toRem(40px);
    }
  }

  &__submit-all {
    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__empty-state {
    border: variables.$standard-border;
  }
}
