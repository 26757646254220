@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

$slide-outro-offset: translateX(#{utils.toRem(-260px)});

@keyframes outro-slide-3 {
  0% {
    transform: translateX(-100%);
    overflow: visible;
  }
  100% {
    transform: translateX(0);
    overflow: visible;
  }
}

@keyframes outro-slide-2 {
  0% {
    opacity: 0;
    transform: $slide-outro-offset;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes outro-slide-1 {
  0% {
    opacity: 0;
    transform: $slide-outro-offset;
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.carousel {
  width: 100%;

  &:focus {
    outline: none;
  }

  &__outer-wrapper {
    max-width: 100vw;
    overflow: hidden;

    &:global(.outro-wrapper) {
      overflow: visible;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: transform 1000ms cubic-bezier(0.17, 0.17, 0.67, 1);
  }

  &__slide {
    width: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 250ms cubic-bezier(0.17, 0.17, 0.67, 1);

    &--active {
      transition-delay: 500ms;
      transition-duration: 500ms;
      opacity: 1;
    }

    :global(.outro-wrapper) & {
      flex-shrink: initial;
      max-width: 33.33%;
      overflow: hidden;
    }

    &:global(.outro) {
      animation-duration: 417ms;
      animation-timing-function: cubic-bezier(0.33, 0, 0.67, 1);
    }

    &:nth-child(3):global(.outro) {
      animation-name: outro-slide-3;
    }

    &:nth-child(2):global(.outro) {
      animation-name: outro-slide-2;
      opacity: 1;
    }

    &:nth-child(1):global(.outro) {
      animation-name: outro-slide-1;
      opacity: 1;
    }
  }

  &__pagination-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: utils.toRem(26px);

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(16px);
    }
  }

  &__bullet-wrapper {
    display: flex;
    align-items: center;
    padding: utils.toRem(8px);
    cursor: pointer;
    outline: none;
    border: 4px solid transparent;
    border-radius: utils.toRem(40px);
    transition: border-color 300ms ease-in-out;

    &:focus {
      border-color: colors.$secondary-color-400;
    }

    &:not(:last-child) {
      margin-right: utils.toRem(8px);
    }
  }

  &__bullet {
    display: inline-block;
    width: utils.toRem(8px);
    height: utils.toRem(8px);
    background-color: colors.$surface-color-stroke;
    border-radius: 50%;
    transition: background-color 300ms ease-in-out;

    &--active {
      background-color: colors.$secondary-color-700;
    }
  }
}
