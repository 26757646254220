@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.email-verified-container {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: utils.toRem(36px) utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    align-items: flex-start;
    max-width: calc(utils.toRem(432px) + utils.toRem(64px));
    margin-right: math.percentage(math.div(138px, 1440px));
    padding: utils.toRem(36px) utils.toRem(32px);
  }

  &__inner {
    width: 100%;
    max-width: calc(utils.toRem(432px) + utils.toRem(64px));
  }

  &__item {
    width: 100%;
    margin-bottom: utils.toRem(16px);
  }

  &__header {
    @include typekit.font-display-6;
    text-align: left;
    color: colors.$surface-color-active;
    display: block;
    margin-bottom: utils.toRem(8px);
  }

  &__subtitle {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);
    width: 100%;
  }
}
