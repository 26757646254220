@use 'sass:math';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: variables.$standard-border;
  padding: utils.toRem(12px) utils.toRem(16px);

  &__avatar {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    border-radius: 50%;
    background-color: colors.$skeleton-mask;
    flex-shrink: 0;
  }

  &__text-wrapper {
    width: 100%;
    margin-left: utils.toRem(16px);
  }

  &__role {
    height: utils.toRem(16px);
    width: math.percentage(math.div(60px, 216px));
    background-color: colors.$skeleton-mask;
  }

  &__name {
    height: utils.toRem(16px);
    width: math.percentage(math.div(150px, 216px));
    margin-top: utils.toRem(8px);
    margin-bottom: utils.toRem(8px);
    background-color: colors.$skeleton-mask;
  }

  &__subtitle {
    height: utils.toRem(16px);
    width: math.percentage(math.div(150px, 216px));
    background-color: colors.$skeleton-mask;
  }
}
