@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';

.contact-us-skeleton {
  min-width: utils.toRem(619px);
  display: flex;
  flex-direction: column;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    flex-direction: row;
  }

  &:first-child {
    padding-top: utils.toRem(0px);

    @include breakpoints.query('>=lg') {
      padding-top: utils.toRem(16px);
    }
  }

  &__user {
    width: utils.toRem(256px);
    display: flex;
    align-items: center;
  }

  &__profile-picture {
    background-color: colors.$skeleton-mask;
    border-radius: 50%;
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    flex-shrink: 0;
  }

  &__user-titles {
    padding-left: utils.toRem(20px);
    width: 100%;
    height: utils.toRem(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__user-title {
    background-color: colors.$skeleton-mask;
    width: math.percentage(math.div(116px, 216px));
    height: utils.toRem(20px);
    margin-bottom: utils.toRem(8px);
  }

  &__user-subtitle {
    background-color: colors.$skeleton-mask;
    width: 100%;
    height: utils.toRem(20px);
  }

  &__support-line {
    display: none;

    @include breakpoints.query('>=lg') {
      @include hero.hero-line-divider;
      display: block;
      border-color: colors.$surface-color-stroke;
    }
  }

  &__user-details {
    width: math.percentage(math.div(170px, 266px));
    height: utils.toRem(60px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: utils.toRem(60px);

    @include breakpoints.query('>=lg') {
      width: utils.toRem(256px);
      padding-left: utils.toRem(0px);
    }
  }

  &__user-detail {
    background-color: colors.$skeleton-mask;
    width: math.percentage(math.div(200px, 266px));
    height: utils.toRem(20px);
    display: flex;
    align-items: center;

    &:last-child {
      margin-top: utils.toRem(8px);
    }
  }
}
