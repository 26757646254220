@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.summary-section {
  margin-bottom: utils.toRem(32px);

  &__title {
    @include typekit.font-subtitle-2;
    margin-bottom: utils.toRem(8px);
    display: block;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      margin-bottom: utils.toRem(16px);
    }
  }
}

.summary-sub-section {
  padding: utils.toRem(16px);
  background-color: colors.$surface-color-variant;
  margin-bottom: utils.toRem(8px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(16px);
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: utils.toRem(20px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(24px);
    }
  }

  &__title {
    @include typekit.font-subtitle-2-bold;
    display: block;
    color: colors.$surface-color-active;
  }
}

.summary-field {
  &:not(:last-child) {
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(24px);
    }
  }

  &__name {
    @include typekit.font-subtitle-2;
    display: block;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-2;
    }

    &--green {
      color: colors.$success-green;
    }
  }

  &__value {
    @include typekit.font-body-2;
    display: block;
    color: rgba($color: colors.$surface-color-active, $alpha: 0.7);
    word-break: break-all;
  }
}

.summary-section-edit {
  cursor: pointer;
  outline: 4px solid transparent;
  transition: outline-color 300ms ease-in-out;

  &:focus {
    outline-color: colors.$secondary-color-400;
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$primary-color-400;
  }
}
