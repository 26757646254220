@keyframes spinner-rotate-left {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes spinner-rotate-right {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@mixin spinner-rotate-left-animation {
  animation-name: spinner-rotate-left;
  animation-duration: 833ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
