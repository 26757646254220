@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.recent-purchases-section {
  @include layout.container-md;

  &__wrapper {
    padding: utils.toRem(32px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(48px) 0;
    }
  }
}

.section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: utils.toRem(32px);
  flex-direction: column;

  @include breakpoints.query('>=md') {
    flex-direction: row;
    margin-bottom: utils.toRem(36px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-block: utils.toRem(16px);
    display: block;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
      margin-block: utils.toRem(24px) utils.toRem(16px);
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    max-width: utils.toRem(577px);
    display: block;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      margin-bottom: 0;
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
    }
  }
}

.legend-wrapper {
  margin-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(20px);
  }
}

.spinner-wrapper {
  border: variables.$standard-border;
  padding: utils.toRem(32px) 0;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(78px) 0;
  }
}
