@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/buttons';
@use 'src/assets/styles/base/typekit';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: variables.$z-index-header;
  width: 100%;
  border-bottom: variables.$standard-border;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  &__skip-to-main {
    &:focus {
      @include typekit.font-subtitle-1;
      background-color: colors.$white;
      color: colors.$surface-color-active;
      outline: none;
      border: utils.toRem(4px) solid colors.$secondary-color-400;
    }
  }
}

.h-desktop {
  background-color: colors.$white;
  display: flex;
  align-items: center;
  padding: 0 utils.toRem(48px);
  height: variables.$header-height-desktop;

  &__navigation-wrapper {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  &__branding {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: utils.toRem(32px);
    min-width: utils.toRem(180px);
    height: 100%;
  }

  &__logo-wrapper {
    height: inherit;
    display: flex;
    align-items: center;

    &:focus {
      outline: utils.toRem(4px) solid colors.$secondary-color-400;
    }
  }

  &__my-bayer-logo {
    width: utils.toRem(150px);
  }

  &__bayer-logo {
    width: utils.toRem(45px);
    margin-left: utils.toRem(12px);
  }

  &__text-wrapper {
    margin-left: utils.toRem(8px);
  }

  &__title {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
  }

  &__subtext {
    @include typekit.font-caption;
    color: colors.$surface-color-inactive-med;
  }

  &__navigation {
    height: 100%;
  }

  &__navigation-list {
    display: flex;
    height: 100%;
  }

  &__navigation-item {
    margin-right: utils.toRem(16px);
    height: 100%;
  }

  &__navigation-link {
    @include typekit.font-subtitle-1;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 utils.toRem(16px);
    color: colors.$surface-color-active;
    @include buttons.nav-link('--active');
  }

  &__right-container {
    height: 100%;
    display: flex;
  }

  &__language-wrapper {
    height: 100%;
  }

  &__account-wrapper {
    height: 100%;
  }

  &__separator-wrapper {
    padding: utils.toRem(20px) utils.toRem(24px);
    height: 100%;
  }

  &__separator {
    height: 100%;
    width: 1px;
    background-color: colors.$surface-color-stroke;
  }
}

.h-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 utils.toRem(16px);
  height: variables.$header-height-mobile;
  background-color: colors.$white;
  transition: background-color 300ms ease-in-out;

  &:global(.open) {
    background-color: colors.$secondary-color-900;
  }

  &__branding {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    min-width: utils.toRem(128px);
    height: 100%;
  }

  &__logo-wrapper {
    height: inherit;
    display: flex;
    align-items: center;

    &:focus {
      outline: utils.toRem(4px) solid colors.$secondary-color-400;
    }
  }

  &__my-bayer-logo {
    width: utils.toRem(120px);
  }

  &__bayer-logo {
    width: utils.toRem(40px);
    margin-left: utils.toRem(16px);
  }

  &__text-wrapper {
    margin-left: utils.toRem(8px);
  }

  &__title {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    transition: color 300ms ease-in-out;

    :global(.open) & {
      color: colors.$white;
    }
  }

  &__subtext {
    @include typekit.font-caption;
    color: colors.$surface-color-inactive-med;
    transition: color 300ms ease-in-out;

    :global(.open) & {
      color: colors.$white;
    }
  }

  &__hamburger-button {
    height: utils.toRem(48px);
    display: flex;
    align-items: center;
    padding: utils.toRem(18px) utils.toRem(15px);
    cursor: pointer;

    &:focus {
      outline: utils.toRem(4px) solid colors.$secondary-color-400;
    }
  }

  &__hamburger {
    position: relative;
    display: inline-block;
    width: utils.toRem(18px);
    height: 2px;
    background-color: colors.$secondary-color-900;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      display: inline-block;
      width: utils.toRem(18px);
      height: 2px;
      background-color: colors.$secondary-color-900;
      transform: rotate(0deg);
      transition: transform 300ms ease-in-out, top 300ms ease-in-out;

      :global(.open) & {
        background-color: colors.$white;
      }
    }

    &::before {
      top: utils.toRem(-5px);

      :global(.open) & {
        top: 0;
        transform: rotate(45deg);
      }
    }

    &::after {
      top: calc(100% + #{utils.toRem(3px)});

      :global(.open) & {
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &__menu {
    position: fixed;
    top: variables.$header-height-mobile;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: colors.$white;
    z-index: variables.$z-index-header;
    padding: utils.toRem(16px);
    transform: translateX(100%);
    transition: transform 300ms ease-in-out;

    @include breakpoints.query('>=md') {
      left: unset;
      width: utils.toRem(375px);
    }

    :global(.open) & {
      transform: translateX(0%);
    }
  }

  &__account-info {
    display: flex;
    align-items: center;
    padding: utils.toRem(10px) utils.toRem(16px);
    margin-bottom: utils.toRem(16px);
    border: variables.$standard-border;
  }

  &__avatar-wrapper {
    margin-right: utils.toRem(16px);
  }

  &__account-code {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__account-name {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
  }

  &__navigation-link {
    @include typekit.font-subtitle-2;
    @include buttons.nav-link;
    color: colors.$surface-color-active;
    display: flex;
    width: 100%;
    align-items: center;
    padding: utils.toRem(16px);
  }

  &__logout-button{
    display: flex;
    justify-content: space-between;
  }

  &__logout-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
    fill-opacity: 1;
  }

  &__hr {
    height: 1px;
    border: none;
    margin: utils.toRem(16px) 0;
    background-color: colors.$surface-color-stroke;
  }

  &__overlay {
    position: fixed;
    top: variables.$header-height-mobile;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: variables.$z-index-header;
    background-color: rgba($color: colors.$black, $alpha: 0.3);
    opacity: 0;
    transform: translateX(100%);
    transition: opacity 300ms ease-in-out;

    :global(.open) & {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  &__bold {
    font-weight: 700;
  }
}
