@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';

.security {
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(32px);
    }
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__subtitle {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(32px);
    }
  }

  &__divider {
    width: 100%;
    border-top: variables.$standard-border;
    margin: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(349px);
      margin: utils.toRem(32px) 0;
    }
  }

  &__text-field {
    &--hide {
      display: none;
    }
  }
}

.form-input {
  width: 100%;

  &--error-required {
    margin-bottom: utils.toRem(6px);
  }

  @include breakpoints.query('>=md') {
    max-width: utils.toRem(349px);
  }
}
