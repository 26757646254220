@use 'src/assets/styles/base/table';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';

.table {
  @include table.default;

  &__header {
    padding: utils.toRem(8px);

    &--row {
      &:first-of-type,
      &:last-of-type {
        padding-inline: 0;
      }
    }

    @include breakpoints.query('>=md') {
      padding: utils.toRem(16px);

      &--row {
        &:first-of-type,
        &:last-of-type {
          padding-inline: utils.toRem(16px);
        }
      }
    }
  }

  &__data-cell {
    &--double-line {
      height: utils.toRem(50px);
    }

    @include breakpoints.query('>=md') {
      &:first-of-type {
        padding-left: utils.toRem(16px);
      }

      &:last-of-type {
        padding-right: utils.toRem(16px);
      }
    }
  }
}
