@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.recent-purchases-table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;

  @include breakpoints.query('>=lg') {
    table-layout: fixed;
  }
}

.table-header {
  border: variables.$standard-border;
  display: none;

  @include breakpoints.query('>=lg') {
    display: table-header-group;
  }

  &__cell {
    @include typekit.font-overline;
    padding: utils.toRem(12px) utils.toRem(16px);
    color: colors.$surface-color-inactive-med;
    text-align: left;

    &--text-right {
      text-align: right;
    }

    &--text-center {
      text-align: center;
    }

    &--accordion {
      @include breakpoints.query('>=lg') {
        width: utils.toRem(72px);
      }
    }

    &--product {
      @include breakpoints.query('>=lg') {
        width: math.percentage(math.div(262px, 1120px));
      }

      :global(.orders) & {
        @include breakpoints.query('>=lg') {
          width: math.percentage(math.div(310px, 1120px));
        }
      }
    }

    &--quantity {
      @include breakpoints.query('>=lg') {
        width: utils.toRem(105px);
      }
    }

    &--date {
      @include breakpoints.query('>=lg') {
        width: math.percentage(math.div(150px, 1120px));
      }

      :global(.orders) & {
        @include breakpoints.query('>=lg') {
          width: math.percentage(math.div(321px, 1120px));
        }
      }
    }

    &--program {
      @include breakpoints.query('>=lg') {
        width: math.percentage(math.div(220px, 1120px));
      }
    }

    &--retailer {
      @include breakpoints.query('>=lg') {
        width: math.percentage(math.div(312px, 1120px));
      }
    }

    &--purchaser {
      @include breakpoints.query('>=lg') {
        width: math.percentage(math.div(146px, 1120px));
      }
    }
  }

  &__cell-wrap {
    display: flex;
    align-items: center;

    &--center {
      justify-content: center;
    }
  }

  &__button {
    cursor: pointer;
    margin-left: utils.toRem(8px);
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }

  &__icon {
    width: utils.toRem(20px);

    &--ascending {
      transform: rotate(180deg);
    }
  }
}

.table-row {
  @include breakpoints.query('>=lg') {
    border: variables.$standard-border;
    border-top: none;
  }

  &--with-bg {
    background-color: colors.$surface-color-variant;
  }
}
