@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';

.language-dropdown {
  @include breakpoints.query('>=xl') {
    height: 100%;
  }

  &__icon {
    fill: colors.$surface-color-active;
    fill-opacity: 1;
  }
}
