@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';

.media-with-content {
  width: 100%;

  @include breakpoints.query('>=md') {
    @include layout.container-md;
  }

  &__content-wrapper {
    width: 100%;

    @include breakpoints.query('>=md') {
      position: relative;
      padding: utils.toRem(48px);
    }
  }

  &__image {
    display: block;
    width: 100%;
    height: 100%;

    @include breakpoints.query('>=md') {
      position: absolute;
      object-fit: cover;
      object-position: center;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  &__children-wrapper {
    width: 100%;

    @include breakpoints.query('>=md') {
      position: relative;
      display: flex;
      z-index: 2;

      &--left {
        justify-content: flex-start;
      }

      &--right {
        justify-content: flex-end;
      }
    }
  }
}
