@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.redirect-login-banner {
  border: solid 1px colors.$surface-color-stroke;
  background-color: colors.$secondary-color-state-01;
  color: colors.$surface-color-active;
  padding: utils.toRem(16px);
  margin-bottom: utils.toRem(24px);

  &__button {
    width: 100%;
  }

  &__text {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
  }
}
