@use '../abstracts/colors';
@use '../abstracts/utils';

/*
  Styles for the `ot-sdk-btn` class:
  
  This button is responsible for activating the OneTrust modal that allows users
  to manage their cookie preferences. The following custom styles are applied
  to ensure that the button's appearance overrides the default OneTrust styles, 
  which are often loaded dynamically or inline.

  By using `!important` flags where necessary, these custom styles ensure 
  that the button matches the desired design and 
  interacts correctly with the rest of the UI, 
  while still functioning as expected to trigger the OneTrust cookie management modal.
*/
#ot-sdk-btn {
  border-color: transparent !important;
  color: colors.$white !important;
  font-size: utils.toRem(12px) !important;
  line-height: utils.toRem(16px) !important;
  padding: 0 utils.toRem(1px) !important;
  text-decoration: underline;

  &:hover {
    background-color: colors.$dark-theme-color-state-01 !important;
    border-color: transparent !important;
  }

  &:focus {
    background-color: colors.$dark-theme-color-state-01 !important;
    border-color: colors.$white !important;
  }

  &:active {
    background-color: colors.$secondary-color-700 !important;
    border-color: transparent !important;
  }
}