@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.upload-photo {
  display: flex;
  align-items: center;
  flex-direction: column;

  @include breakpoints.query('>=md') {
    flex-direction: row;
  }

  &__photo {
    width: utils.toRem(48px);
    height: utils.toRem(48px);
    margin-bottom: utils.toRem(16px);
    border-radius: 50%;

    @include breakpoints.query('>=md') {
      margin-bottom: 0;
      margin-right: utils.toRem(16px);
    }

    &--md {
      @include breakpoints.query('>=md') {
        width: utils.toRem(64px);
        height: utils.toRem(64px);
        margin-right: utils.toRem(24px);
      }
    }

    &--lg {
      @include breakpoints.query('>=md') {
        width: utils.toRem(80px);
        height: utils.toRem(80px);
        margin-right: utils.toRem(16px);
      }
    }
  }

  &__text-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__name {
    display: none;
    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      display: block;
      color: colors.$surface-color-active;
      margin-bottom: utils.toRem(8px);
    }
  }

  &__input {
    display: none;
  }

  &__button-wrapper {
    display: flex;
  }

  &__separator {
    height: 100%;
    width: 1px;
    background-color: colors.$surface-color-stroke;
    margin: 0 utils.toRem(20px);
  }
}
