@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.option {
  border: variables.$inactive-border;
  background-color: transparent;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  &__focus {
    background-color: colors.$primary-color-state-01;
    border-color: colors.$secondary-color-400;
  }

  &:hover {
    background-color: colors.$primary-color-state-01;
  }
}

.option-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1.125rem;
}

.option-content {
  margin-right: utils.toRem(18px);
  overflow: hidden;
  max-width: 100%;
}

.option-label-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.option-label {
  @include typekit.font-subtitle-1;
  color: colors.$secondary-color-900;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.option-label-icon {
  margin-right: utils.toRem(16px);
}

.option-description {
  @include typekit.font-body-2;
  color: colors.$surface-color-inactive-med;
}

.option-radio {
  min-width: utils.toRem(20px);
  width: utils.toRem(20px);
  height: utils.toRem(20px);
  border: 2px solid colors.$surface-color-inactive-med;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--selected {
    border-color: colors.$primary-color-400;

    &:after {
      content: '';
      width: utils.toRem(10px);
      height: utils.toRem(10px);
      background-color: colors.$primary-color-400;
      border-radius: 50%;
    }
  }

  &--disabled {
    border-color: colors.$disabled-color;

    &:after {
      background-color: colors.$disabled-color;
    }
  }

  &--multiple {
    border-radius: 2px;
    min-width: utils.toRem(18px);
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    position: relative;

    svg {
      fill: colors.$white;
      position: absolute;
    }

    &:after {
      border-radius: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.helper-text {
  @include typekit.font-caption;
  margin-left: utils.toRem(16px);
  color: colors.$surface-color-inactive-med;

  &--errors {
    color: colors.$danger-color-700;
  }

  &--disabled {
    color: colors.$disabled-color;
  }
}

.select-wrapper {
  width: 100%;
}

.loading {
  @include animations.spinner-rotate-left-animation;
}

.multi-container {
  max-width: 90%;
  overflow: hidden;
  white-space: nowrap;
  color: colors.$surface-color-active;

  &--disabled {
    color: colors.$disabled-color;
  }
}