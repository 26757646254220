@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.disclaimer {
  background-color: rgba($color: colors.$danger-color-700, $alpha: 0.25);
  border: 1px solid colors.$danger-color-700;
  padding: utils.toRem(12px) utils.toRem(16px);
  color: colors.$surface-color-active;

  &__title {
    @include typekit.font-subtitle-2-bold;
  }

  &__content {
    @include typekit.font-body-2;
  }
}
