@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.enrolled {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;

  &__card-wrapper {
    padding: utils.toRem(24px) utils.toRem(16px) utils.toRem(24px);

    @include breakpoints.query('>=md') {
      padding-inline: utils.toRem(24px) utils.toRem(39px);
    }
  }

  &__retreat-section {
    padding-inline: utils.toRem(24px);
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__footer {
    margin-top: utils.toRem(24px);
    display: flex;
    align-items: center;
    padding: utils.toRem(16px);
    border-top: variables.$standard-border;
  }

  &__icon {
    fill: colors.$surface-color-inactive-med;
    width: utils.toRem(16px);
    height: utils.toRem(16px);
    margin-right: utils.toRem(8px);
  }

  &__footer-text {
    color: colors.$surface-color-inactive-med;
  }
}
