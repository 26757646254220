@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.arc {
  display: flex;
  align-items: center;
  border: variables.$standard-border;
  padding: utils.toRem(12px) utils.toRem(16px);
  background-color: colors.$white;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(12px) utils.toRem(24px) utils.toRem(12px) utils.toRem(16px);
  }

  &--selected {
    background-color: colors.$secondary-color-state-02;
    border-color: colors.$secondary-color-700;
  }

  &--w-button {
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }
  }

  &--w-button &__top-wrapper,
  &--w-button &__icon-wrapper {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
    }
  }

  &--w-button &__top-wrapper {
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-bottom: 0;
    }
  }

  &__top-wrapper {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  &__address-wrapper {
    flex-grow: 1;
  }

  &__icon-wrapper {
    justify-self: flex-end;
    flex-shrink: 0;
  }

  &__ret-name {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
  }

  &__ret-contact {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__ret-information {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;

    &--selected {
      color: colors.$surface-color-active;
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
      min-width: utils.toRem(175px);
    }
  }

  &__icon {
    width: utils.toRem(24px);
    cursor: pointer;
  }

  &__loading-icon {
    @include animations.spinner-rotate-left-animation;
  }

  &__eyebrow {
    @include typekit.font-overline;
    margin-bottom: utils.toRem(4px);
    color: colors.$surface-color-inactive-med;
  }
}
