@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/buttons';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.filter-section {
  height: auto;
  margin-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(48px);
  }

  &__form {
    width: 100%;
  }

  &__search {
    @include breakpoints.query('>=lg') {
      max-width: utils.toRem(577px);
    }
  }

  &__loading {
    height: utils.toRem(48px);
    width: 100%;
    max-width: utils.toRem(577px);
    margin-top: utils.toRem(24px);
    background-color: colors.$surface-color-stroke;
  }

  &__row-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=lg') {
      margin-top: utils.toRem(24px);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__filters-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @include breakpoints.query('>=lg') {
      align-items: center;
    }
  }

  &__dropdown {
    padding: 0 utils.toRem(16px);

    @include breakpoints.query('>=lg') {
      min-width: utils.toRem(219px);
    }

    &--date-range {
      padding: 0;
    }
  }

  &__item {
    margin: 0 utils.toRem(8px);
    &:first-of-type {
      margin: 0 utils.toRem(8px) 0 0;
    }
  }

  &__subtitle {
    @include typekit.font-body-2;
    width: 100%;
    display: block;
    margin-top: utils.toRem(16px);
    margin-bottom: utils.toRem(12px);
    margin-right: utils.toRem(8px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=lg') {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__all-filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include breakpoints.query('>=lg') {
      width: auto;
    }
  }

  &__reset-button {
    @include typekit.font-body-1;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }
}

.mobile-filters {
  padding: 0 utils.toRem(16px);
  margin-bottom: utils.toRem(24px);

  &__section {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:not(:last-child) {
      hr {
        width: 100%;
        color: colors.$surface-color-stroke;
        size: 1px;
        margin: utils.toRem(24px) 0;
      }
    }
  }

  &__heading {
    @include typekit.font-subtitle-1;
    margin-bottom: utils.toRem(8px);
    color: colors.$surface-color-active;
  }

  &__back-button-wrapper {
    position: fixed;
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    top: utils.toRem(27px);
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }

    @include breakpoints.query('>=md') {
      left: utils.toRem(32px);
    }
  }

  &__back-button {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$surface-color-active;
    margin-right: utils.toRem(10px);
    cursor: pointer;
  }
}

.checkbox {
  align-items: center;
  padding: utils.toRem(8px) utils.toRem(8px);

  @include breakpoints.query('>=lg') {
    padding: utils.toRem(8px) 0;
  }

  &__label {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
    margin-left: utils.toRem(8px);
    padding: 0;

    &--multi {
      padding-top: utils.toRem(6px);
    }
  }
}
