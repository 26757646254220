@use 'sass:math';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.rewards-progress-bar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__bar {
    width: 100%;
    height: utils.toRem(8px);
    appearance: none; // Apple Safari, Edge and Google Chrome
    background-color: colors.$surface-color-stroke; /* Needed for Firefox */
    border: none; /* Needed for Firefox */
    color: colors.$secondary-color-400;

    &::-webkit-progress-value {
      background-color: colors.$secondary-color-400;
      border-radius: utils.toRem(10px);
    }

    &::-moz-progress-bar {
      background-color: colors.$secondary-color-400;
      border-radius: utils.toRem(10px);
    }

    &::-webkit-progress-bar {
      background-color: colors.$surface-color-stroke;
      border-radius: utils.toRem(10px);
    }
  }

  &__deco {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    border: 2px solid colors.$surface-color-stroke;
    border-radius: utils.toRem(40px);
    background-color: colors.$white;
    margin-left: utils.toRem(-8px);
    padding: utils.toRem(8px);

    &--complete {
      background-color: colors.$secondary-color-700;
    }
  }

  &__airplane {
    fill: colors.$secondary-color-700;

    &--complete {
      fill: colors.$white;
    }
  }
}
