@use '../abstracts/colors';
@use '../abstracts/breakpoints';
@use '../abstracts/utils';

@mixin container-md {
  max-width: utils.toRem(1120px + 112px);
  margin: 0 auto;
  padding-left: utils.toRem(16px);
  padding-right: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    padding-left: utils.toRem(32px);
    padding-right: utils.toRem(32px);
  }

  @include breakpoints.query('>=xl') {
    padding-left: utils.toRem(56px);
    padding-right: utils.toRem(56px);
  }
}

@mixin container-xl {
  max-width: utils.toRem(1440px);
  margin: 0 auto;
}

@mixin page-section-divider {
  margin: 0 auto;
  height: 1px;
  width: 95%;
  background-color: colors.$surface-color-stroke;
  border: none;

  @include breakpoints.query('>=md') {
    max-width: calc(100% - #{utils.toRem(64px)});
  }

  @include breakpoints.query('>=lg') {
    max-width: min(calc(100% - #{utils.toRem(112px)}), utils.toRem(1120px));
  }

  @include breakpoints.query('>=xl') {
    max-width: utils.toRem(1120px);
  }

  &--mobile-hidden {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
    }
  }
}

@mixin hide-scrollbar {
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Safari, Edge and Chrome */
  }
}

@mixin center-in-window {
  width: 100%;
  height: 100%;

  @include breakpoints.query('>=md') {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
