@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.event-pass-widget {
  &__modal-wrapper {
    padding: 0;
  }

  &__modal-header {
    padding-inline: utils.toRem(8px);
  }

  &__modal-content {
    background-color: colors.$surface-color-active;
  }

  &__modal-footer {
    padding-inline: utils.toRem(24px);

    &::before {
      display: none;
    }
  }

  &__content-wrapper {
    padding-bottom: utils.toRem(40px);
    background-color: colors.$surface-color-active;
  }

  &__sections-wrapper {
    color: colors.$white;
    padding: 0 utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);
  }

  &__image {
    width: 100%;
    height: utils.toRem(150px);
    object-fit: cover;
    object-position: right;
    align-self: stretch;
    flex-shrink: 0;
  }

  &__logo {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    flex-shrink: 0;
  }

  &__event-header {
    display: flex;
    align-items: center;
    padding-block: utils.toRem(8px);
    gap: utils.toRem(16px);
  }

  &__event-information {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.toRem(16px);
  }

  &__information-wrapper {
    display: block;

    &--account {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__account-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__label {
    @include typekit.font-overline;
  }

  &__value {
    @include typekit.font-subtitle-2;

    &--lg {
      @include typekit.font-display-5;
    }
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }
}
