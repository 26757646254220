@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.farm-card {
  display: flex;
  align-items: center;
  padding: utils.toRem(12px) utils.toRem(16px);

  &__avatar-wrapper {
    flex-shrink: 0;
  }

  &__text-wrapper {
    margin-left: utils.toRem(16px);
  }

  &__role {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__name {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    margin-top: utils.toRem(2px);
    margin-bottom: utils.toRem(2px);

    @include breakpoints.query('>=xl') {
      @include typekit.font-subtitle-1;
    }
  }

  &__subtitle {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    word-break: break-word;
  }
}
