@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.avatar {
  width: utils.toRem(40px);
  min-width: utils.toRem(40px);
  height: utils.toRem(40px);
  border-radius: 50%;
  background-position: center;
  background-size: cover;

  &__initials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &--primary {
      background-color: colors.$secondary-color-900;
    }

    &--secondary {
      background-color: colors.$primary-color-400;
    }
  }

  &__initials {
    @include typekit.font-display-6;
    color: colors.$white;
  }

  &--loading {
    background-color: colors.$skeleton-mask;
  }
}
