@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.link {
  position: relative;
  color: colors.$primary-color-400;
  text-decoration: underline;
  outline: none;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    top: utils.toRem(-2px);
    right: utils.toRem(-2px);
    bottom: utils.toRem(-2px);
    left: utils.toRem(-2px);
    background-color: transparent;
    border: 1px solid transparent;
    transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;
    z-index: 0;
    cursor: pointer;
  }

  &--text-link {
    &:hover {
      text-decoration: none;

      &::before {
        background-color: colors.$primary-color-state-01;
        border-color: colors.$primary-color-state-01;
      }
    }

    &:focus {
      text-decoration: none;

      &::before {
        background-color: colors.$primary-color-state-01;
        border-color: colors.$primary-color-400;
      }
    }

    &:active {
      &::before {
        background-color: colors.$primary-color-state-03;
        border-color: colors.$primary-color-state-03;
      }
    }

    &:disabled {
      color: colors.$surface-color-inactive-low;
    }
  }

  &--text-link-dark {
    color: colors.$white;

    &:hover {
      &::before {
        background-color: colors.$dark-theme-color-state-01;
        border-color: transparent;
      }
    }

    &:focus {
      &::before {
        background-color: colors.$dark-theme-color-state-01;
        border-color: colors.$white;
      }
    }

    &:active {
      &::before {
        background-color: colors.$secondary-color-700;
        border-color: transparent;
      }
    }

    &:disabled {
      &::before {
        color: colors.$disabled-color-stroke;
      }
    }
  }

  &__content {
    position: relative;
    z-index: 1;
  }

  &--i-left,
  &--i-right {
    display: inline-flex;
    align-items: center;
  }

  &--i-left {
    flex-direction: row-reverse;
  }

  &__icon {
    position: relative;
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    margin-top: -1px;
    margin-bottom: -1px;
    fill: currentColor;
    z-index: 1;

    &--left {
      margin-right: utils.toRem(8px);
    }

    &--right {
      margin-left: utils.toRem(8px);
    }
  }
}
