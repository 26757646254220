@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.date-range-filter {
  cursor: pointer;

  &__custom {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
    display: flex;
    padding: utils.toRem(16px);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-top: variables.$standard-border;
  }

  &__custom-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
  }
}
