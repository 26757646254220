@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.steps-wrapper {
  position: relative;
  padding: utils.toRem(24px) utils.toRem(16px) 0 utils.toRem(16px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__container {
    @include breakpoints.query('>=md') {
      display: flex;
      justify-content: center;
      width: 100%;
      padding: utils.toRem(48px) utils.toRem(32px) 0 utils.toRem(32px);
    }
  }

  &__form {
    @include breakpoints.query('>=md') {
      width: utils.toRem(729px);
    }
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__content {
    padding: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(32px) 0;
    }
  }

  &__header-actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: utils.toRem(16px);
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=sm') {
      flex-direction: row;
      align-items: center;
      gap: utils.toRem(24px);
      margin-bottom: utils.toRem(48px);
    }
  }
}

.steps-wrapper-footer {
  display: flex;
  justify-content: space-between;
  padding: utils.toRem(16px) 0;
  border-top: 1px solid rgba($color: colors.$black, $alpha: 0.12);
  flex-shrink: 0;

  @include breakpoints.query('>=md') {
    position: sticky;
    bottom: 0;
    background-color: colors.$white;
    padding: utils.toRem(12px) utils.toRem(24px);
    z-index: variables.$z-index-steps-footer;
  }

  &__button {
    width: calc(50% - #{4px});

    @include breakpoints.query('>=md') {
      width: utils.toRem(150px);
    }
  }

  &__loading-icon {
    @include animations.spinner-rotate-left-animation;
  }
}