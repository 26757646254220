@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.skeleton {
  width: 100%;
  padding: utils.toRem(16px);
  padding-top: utils.toRem(0px);

  @include breakpoints.query('>=md') {
    padding: utils.toRem(30px) utils.toRem(24px);
  }

  &__metrics-inner {
    @include breakpoints.query('>=md') {
      display: flex;
      align-items: center;
    }
  }

  &__donut-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: utils.toRem(64px);
    padding-bottom: utils.toRem(80px);

    @include breakpoints.query('>=md') {
      display: block;
      max-width: utils.toRem(196px);
      margin-left: utils.toRem(90px);
      margin-right: utils.toRem(24px);
      margin-right: utils.toRem(180px);
    }

    @include breakpoints.query('>=lg') {
      margin-right: utils.toRem(106px);
    }
  }

  &__information {
    align-self: flex-start;

    @include breakpoints.query('>=md') {
      width: 100%;
      margin-left: utils.toRem(40px);
    }
  }

  &__metrics-heading {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
    }
  }

  &__cards-wrapper {
    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(32px);
    }
  }

  &__metrics-title {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__metrics-subtitle {
    background-color: colors.$skeleton-mask;
    width: math.percentage(math.div(155px, 242px));
    height: utils.toRem(32px);
    margin-top: utils.toRem(8px);
  }

  &__card {
    background-color: colors.$skeleton-mask;
    width: math.percentage(math.div(281px, 281px));
    height: utils.toRem(44px);
    margin-top: utils.toRem(16px);
  }

  &__divider {
    display: none;
    border-color: colors.$surface-color-stroke;

    @include breakpoints.query('>=md') {
      display: block;
      margin-top: utils.toRem(4px);
      margin-bottom: utils.toRem(24px);
    }
  }
}
