@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.program-card {
  display: flex;

  &--top {
    align-items: flex-start;
  }

  &--center {
    align-items: center;
  }

  &--horizontal {
    gap: utils.toRem(24px);
  }

  &--vertical {
    flex-direction: column;
    gap: utils.toRem(8px);

    @include breakpoints.query('<md') {
      align-items: flex-start;
    }

    @include breakpoints.query('>=md') {
      flex-direction: row;
      gap: utils.toRem(24px);
    }
  }

  &__heading {
    @include typekit.font-subtitle-2-bold;
    color: colors.$surface-color-active;

    &--sm {
      margin-bottom: 0px;
    }

    &--md {
      margin-bottom: utils.toRem(4px);

      @include breakpoints.query('>=md') {
        @include typekit.font-subtitle-1-bold;
      }
    }

    &--lg {
      margin-bottom: utils.toRem(8px);

      @include breakpoints.query('>=md') {
        @include typekit.font-display-6;
      }
    }
  }

  &__description {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;

    &--sm {
      @include typekit.font-subtitle-2;
    }
  }
}

.program-card-link {
  position: relative;
  display: block;
  border: variables.$standard-border;
  padding: utils.toRem(16px);
  padding-right: utils.toRem(56px);
  background-color: transparent;
  transition: background-color 300ms ease-in-out;

  @include breakpoints.query('>=md') {
    display: flex;
    align-items: center;
    padding: utils.toRem(16px) utils.toRem(72px) utils.toRem(16px) utils.toRem(24px);
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid transparent;
    transition: border-color 300ms ease-in-out, border-width 300ms ease-in-out;
  }

  &:hover {
    background-color: colors.$surface-color-variant;
  }

  &:focus {
    outline: none;
    background-color: colors.$surface-color-variant;

    &::before {
      border-width: utils.toRem(4px);
      border-color: colors.$secondary-color-400;
    }
  }

  &:active {
    background-color: colors.$surface-color-variant;

    &::before {
      border-width: utils.toRem(1px);
      border-color: colors.$surface-color-active;
    }
  }

  &__icon-wrapper {
    position: absolute;
    top: utils.toRem(16px);
    right: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      top: 50%;
      right: utils.toRem(24px);
      transform: translateY(-50%);
    }
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$surface-color-active;
  }
}

.program-card-pill {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__pill-wrapper {
    margin-left: utils.toRem(8px);
  }
}
