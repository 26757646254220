@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.welcome-message {
  &__title-overline {
    @include typekit.font-overline;
    color: colors.$white;
  }

  &__subtitle {
    @include typekit.font-display-5;
    margin-top: utils.toRem(16px);
    color: colors.$white;

    @include breakpoints.query('>=xl') {
      @include typekit.font-display-4;
      margin-top: utils.toRem(8px);
    }
  }
}
