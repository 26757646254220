@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.order-history {
  @include layout.container-md;
  width: 100%;

  &__hero {
    padding-block: utils.toRem(48px) utils.toRem(24px);
  }

  &__heading {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__heading-secondary {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    padding-block: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
      padding-block: utils.toRem(48px);
    }
  }

  &__sub-heading {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__divider {
    border: none;
    height: 1px;
    background-color: colors.$surface-color-stroke;
  }
}
