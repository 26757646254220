@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.data-visualization {
  width: 100%;
  padding-bottom: utils.toRem(24px);

  @include breakpoints.query('>=md') {
    padding-bottom: utils.toRem(48px);
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(40px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
    }
  }

  &__spinner {
    margin-block: utils.toRem(100px);
  }

  &__inner-accordion {
    padding: 0;
  }
}

.line-chart {
  &__graph-empty {
    height: fit-content;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: start;
    border-bottom: variables.$standard-border;
    padding: utils.toRem(12px) utils.toRem(16px);
    margin-inline: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0;
    }
  }

  &__text-container {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(4px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$secondary-color-900;
  }

  &__subtitle {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
  }

  &__filters {
    @include typekit.font-body-2;
    color: colors.$secondary-color-900;
    text-decoration: none;
    padding-block: utils.toRem(8px);
    margin: 0;
  }
}
