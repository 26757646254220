@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.pac-container {
  padding: utils.toRem(4px) utils.toRem(3px);
  margin-bottom: 1rem;
}

.pac-item {
  outline: variables.$inactive-border;

  &:hover,
  &:focus {
    background-color: colors.$primary-color-state-01;
    outline-color: colors.$secondary-color-400;
  }

  &:first-of-type {
    border-top: none;
  }
}

.pac-item-selected {
  background-color: colors.$primary-color-state-01;
  outline-color: colors.$secondary-color-400;
}

.pac-logo::after {
  display: none;
}
