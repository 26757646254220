@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/form-layout';

.setup {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoints.query('>=lg') {
    flex-direction: row;
  }

  &__content {
    flex: 1;

    @include breakpoints.query('>=md') {
      border-left: 1px solid rgba($color: colors.$surface-color-active, $alpha: 0.1);
    }
  }
}
