@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.verification-method {
  &__button {
    width: 100%;
    margin-top: utils.toRem(24px);

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__form {
    margin-top: utils.toRem(20px);
  }

  &__radio-group {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__radio {
    @include typekit.font-body-2;
    padding: 0 utils.toRem(8px);
  }

  &__description {
    @include typekit.font-body-2;
    display: inline-block;
    color: colors.$surface-color-inactive-med;
  }

  &__phone {
    @include typekit.font-body-2-bold;
    margin: 0 utils.toRem(8px);
  }
}
