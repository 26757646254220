@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.active-orders {
  padding-top: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(24px);
  }

  &__header-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: utils.toRem(24px);
    gap: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      grid-template-columns: 1.2fr 0.8fr;
    }
  }

  &__download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoints.query('>=md') {
      align-items: flex-end;
    }
  }

  &__download-title {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__download-button {
    @include typekit.font-body-1-bold;
  }

  &__table-wrapper {
    margin-bottom: utils.toRem(40px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(48px);
    }
  }

  &__pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__download-button {
    flex-shrink: 0;
    width: fit-content;
  }

  &__text-wrapper {
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      border: variables.$standard-border;
      border-radius: 4px;
      padding: utils.toRem(16px);
      align-items: center;
      flex-direction: row;
    }
  }

  &__text {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
  }
}
