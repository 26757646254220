@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.gsc {
  width: 100%;
}

.gss {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 utils.toRem(16px);

  &__inner-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: utils.toRem(16px);
  }

  &__image {
    width: 63.15%;
    max-width: 100%;
    max-height: utils.toRem(370px);
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      width: auto;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__heading,
  &__description {
    max-width: utils.toRem(405px);
  }

  &__heading {
    @include typekit.font-display-6;
    text-align: center;
    margin-bottom: utils.toRem(32px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__description {
    @include typekit.font-body-2;
    text-align: center;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-2;
    }
  }

  &__video-wrapper {
    position: relative;
    width: 100%;
    max-width: utils.toRem(810px);
    height: 47vw;

    @include breakpoints.query('>=md') {
      height: utils.toRem(400px);
    }

    &--modal {
      @include breakpoints.query('>=md') {
        height: utils.toRem(260px);
      }
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;

    @include breakpoints.query('>=md') {
      height: 100%;
    }

    @include breakpoints.query('>=xl') {
      width: auto;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
  }

  &__video-wrapper--modal &__video {
    @include breakpoints.query('>=md') {
      width: auto;
      left: 50%;
      right: unset;
      transform: translateX(-50%);
    }
  }
}
