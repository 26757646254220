@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.date-range-mobile {
  display: flex;
  flex-direction: column;
  outline: 0;
  width: 100%;
  height: 100%;
  background-color: colors.$white;

  &__subtitle {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-left: utils.toRem(16px);
    }
  }

  &__calendar-section-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      align-items: flex-start;
      max-width: none;
    }
  }

  &__calendar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: utils.toRem(16px);
    width: variables.$calendar-width;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: utils.toRem(14px) utils.toRem(16px);
    background-color: colors.$surface-color-variant;

    &:focus {
      outline: variables.$active-border;
    }

    &--error {
      outline: 1px solid colors.$danger-color-700;
    }
  }

  &__date {
    @include typekit.font-subtitle-1;
    text-align: left;
    color: colors.$surface-color-active;
    background-color: colors.$surface-color-variant;

    &--error {
      color: colors.$danger-color-700;
    }
  }

  &__calendar-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-right: utils.toRem(8px);
    fill: colors.$secondary-color-700;

    &--error {
      fill: colors.$danger-color-700;
    }
  }

  &__dash {
    display: none;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      display: flex;
      height: utils.toRem(52px);
      align-items: center;
      justify-content: center;
      margin: 0 utils.toRem(16px);
    }
  }

  &__error {
    @include typekit.font-caption;
    margin-top: utils.toRem(4px);
    color: colors.$danger-color-700;

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(16px);
      margin-left: utils.toRem(16px);
    }
  }
}
