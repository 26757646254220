@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';

.carousel-wrapper {
  padding-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    padding-bottom: utils.toRem(40px);
  }
}

.modal-content {
  display: flex;
  align-items: center;

  @include breakpoints.query('>=md') {
    display: block;
  }
}
