@use '../abstracts/utils';

@mixin font-display-1 {
  font-style: italic;
  font-weight: 200;
  font-size: utils.toRem(96px);
  line-height: utils.toRem(116px);
  letter-spacing: 1.25px;
}

@mixin font-display-2 {
  font-style: normal;
  font-weight: 200;
  font-size: utils.toRem(60px);
  line-height: utils.toRem(72px);
  letter-spacing: 0.75px;
}

@mixin font-display-3 {
  font-style: italic;
  font-weight: 200;
  font-size: utils.toRem(48px);
  line-height: utils.toRem(56px);
}

@mixin font-display-4 {
  font-style: normal;
  font-weight: 300;
  font-size: utils.toRem(34px);
  line-height: utils.toRem(40px);
  letter-spacing: 0.5px;
}

@mixin font-display-4-bold {
  @include font-display-4;
  font-weight: 500;
}

@mixin font-display-5 {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(24px);
  line-height: utils.toRem(28px);
  letter-spacing: 0.5px;
}

@mixin font-display-6 {
  font-style: normal;
  font-weight: 500;
  font-size: utils.toRem(20px);
  line-height: utils.toRem(24px);
  letter-spacing: 0.25px;
}

@mixin font-subtitle-1 {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(16px);
  line-height: utils.toRem(24px);
  letter-spacing: 0.15px;
}

@mixin font-subtitle-1-bold {
  @include font-subtitle-1;
  font-weight: 700;
}

@mixin font-subtitle-2 {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(14px);
  line-height: utils.toRem(20px);
  letter-spacing: 0.25px;
}

@mixin font-subtitle-2-bold {
  @include font-subtitle-2;
  font-weight: 700;
}

@mixin font-body-1 {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(16px);
  line-height: utils.toRem(28px);
  letter-spacing: 0.5px;
}

@mixin font-body-1-bold {
  @include font-body-1;
  font-weight: 700;
}

@mixin font-body-2 {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(14px);
  line-height: utils.toRem(20px);
  letter-spacing: 0.25px;
}

@mixin font-body-2-bold {
  @include font-body-2;
  font-weight: 700;
}

@mixin font-caption {
  font-style: normal;
  font-weight: 400;
  font-size: utils.toRem(12px);
  line-height: utils.toRem(16px);
  letter-spacing: 0.25px;
}

@mixin font-caption-bold {
  @include font-caption;
  font-weight: 700;
}

@mixin font-overline {
  font-style: normal;
  font-weight: 500;
  font-size: utils.toRem(12px);
  line-height: utils.toRem(16px);
  letter-spacing: 1.75px;
  text-transform: uppercase;
}

@mixin font-button {
  font-family: 'Helvetica Neue';
  font-style: italic;
  font-weight: 700;
  font-size: utils.toRem(14px);
  line-height: utils.toRem(16px);
  letter-spacing: 0.75px;
  text-transform: uppercase;
}
