@use 'sass:math';
@use '../abstracts/colors';
@use '../abstracts/utils';
@use '../abstracts/breakpoints';
@use './typekit';

@mixin hero-line-divider {
  color: colors.$white;
  margin: utils.toRem(32px) 0;

  @include breakpoints.query('>=md') {
    margin: 0 utils.toRem(64px);
  }
}

@mixin hero-text {
  color: white;

  &:not(:last-child) {
    margin-bottom: utils.toRem(16px);
  }

  &--overline {
    @include typekit.font-overline;
  }

  &--heading {
    @include typekit.font-display-5;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
    }
  }

  &--heading-blur {
    color: transparent;
    text-shadow: 0 0 utils.toRem(15px) rgba($color: colors.$white, $alpha: 0.5);
  }

  &--subtitle {
    @include typekit.font-subtitle-2;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &--subtitle-bold {
    @include typekit.font-subtitle-2-bold;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
    }
  }

  &--disclaimer {
    @include typekit.font-body-2-bold;
  }
}

@mixin hero-subtitle-icon {
  fill: colors.$white;
  width: utils.toRem(20px);
  margin-right: utils.toRem(8px);
}
