@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.bayer-rewards {
  display: flex;
  flex-direction: column;
  background-color: colors.$white;

  @include breakpoints.query('>=md') {
    border: variables.$standard-border;
  }

  @include breakpoints.query('>=lg') {
    height: 100%;
  }

  &__card-link {
    border-bottom: variables.$standard-border;
  }

  &__years {
    padding: 0 utils.toRem(16px);
  }

  &__years-inner {
    padding: utils.toRem(16px) 0;
    border-bottom: variables.$standard-border;
  }

  &__empty-state {
    height: 100%;
  }
}
