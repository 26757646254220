@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.calculator {
  width: 100%;

  &__section {
    padding-block: utils.toRem(32px);
  }

  &__divider {
    margin: 0 auto;
    height: 1px;
    background-color: colors.$surface-color-stroke;
    border: none;
    width: 100%;
  }

  &__sub-title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__text {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(4px);

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__crop-list {
    padding-block: utils.toRem(32px) utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__spinner-wrapper {
    padding-block: utils.toRem(64px);
  }

  &__step-wrapper {
    @include breakpoints.query('>=md') {
      padding: 0;
    }
  }

  &__error-message {
    @include typekit.font-caption;
    color: colors.$danger-color-700;
  }
}
