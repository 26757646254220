@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.total-breakdown {
  width: 100%;
  color: colors.$surface-color-active;
  background-color: colors.$white;
  padding-top: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    max-width: utils.toRem(422px);
  }

  &__running-total-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: utils.toRem(24px);
    background-color: colors.$surface-color-variant;
    margin: 0 utils.toRem(16px);
  }

  &__running-total-amount {
    @include typekit.font-display-4;
    font-style: italic;
  }

  &__running-total-title {
    @include typekit.font-body-1-bold;
  }

  &__accordion-container {
    border: none;
    margin-top: utils.toRem(16px);
  }

  button.total-breakdown__accordion-button {
    background-color: colors.$white;

    & svg {
      margin: 0;
    }
  }

  &__product-amount,
  &__product-title,
  &__table-header {
    padding: utils.toRem(8px) 0;
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
    margin-top: utils.toRem(-8px);
  }

  &__table-header {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;

    &--acres {
      padding: utils.toRem(8px);
    }
  }

  &__table-column {
    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }
  }

  &__table-footer {
    border-top: variables.$standard-border;
  }

  &__product-title {
    color: colors.$surface-color-inactive-med;
    @include typekit.font-body-2;
    max-width: utils.toRem(200px);

    &--total {
      color: colors.$surface-color-inactive-med;
      @include typekit.font-body-2-bold;
    }
  }

  &__product-amount {
    margin: 0;
    text-align: right;
    vertical-align: baseline;
    @include typekit.font-body-2;

    &--acres {
      text-align: center;
      padding: utils.toRem(8px);
    }

    &--total {
      @include typekit.font-body-2-bold;
    }
  }
}
