@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.faq {
  @include layout.container-md;
  width: 100%;
  padding-block: utils.toRem(24px) utils.toRem(40px);

  @include breakpoints.query('>=md') {
    padding-block: utils.toRem(48px) utils.toRem(80px);
  }

  &__title {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
      margin-bottom: utils.toRem(16px);
    }
  }
}
