@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.gs {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: utils.toRem(33px);
  width: 100%;
  opacity: 1;
  transition: opacity 500ms ease;

  @include breakpoints.query('>=md') {
    justify-content: center;
    align-items: center;
  }

  &__carousel {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoints.query('>=md') {
      flex-grow: initial;
      max-width: utils.toRem(1310px);
      width: 100%;
    }
  }

  &__buttons-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: utils.toRem(24px);
    padding: utils.toRem(16px) utils.toRem(8px);
    border-top: variables.$standard-border;
    flex-shrink: 0;
    opacity: 1;
    transition: opacity 500ms ease;

    @include breakpoints.query('>=md') {
      border-top: none;
      padding-top: 0;
      max-width: utils.toRem(432px);
    }
  }

  &__buttons-inner-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      flex-direction: column;
    }
  }

  &__button {
    @include breakpoints.query('>=md') {
      width: 100%;
    }

    &--next,
    &--back {
      width: calc(50% - (8px / 2));
      flex-grow: 1;
      flex-shrink: 0;

      @include breakpoints.query('>=md') {
        width: 100%;
      }
    }

    &--back {
      margin-right: utils.toRem(8px);

      @include breakpoints.query('>=md') {
        margin-right: 0;
        margin-top: utils.toRem(8px);
      }
    }

    &--skip {
      opacity: 1;
      transition: opacity 250ms ease;
    }

    &--skip-hidden {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }
  }
}
