@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.aprm {
  height: 100%;

  &__list-item {
    padding: utils.toRem(4px);

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__label {
    cursor: pointer;
  }

  &__loading-icon {
    @include animations.spinner-rotate-left-animation;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    padding-top: utils.toRem(16px);
  }

  &__checkbox:focus-within + .aprm__retailer-card {
    outline: 2px solid colors.$secondary-color-400;
  }
}

.modal-header {
  &__grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: utils.toRem(16px);
  }

  &__field {
    margin-bottom: utils.toRem(16px);
  }

  &__button {
    width: 100%;
  }

  &__legend {
    @include typekit.font-subtitle-2;
    display: block;
    margin-block: utils.toRem(10px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-2;
    }
  }

  &__selected {
    overflow: hidden;
    text-overflow: initial;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &__show-selected {
    display: inline-flex;
    vertical-align: baseline;
    align-items: baseline;
  }

  &__show-selected-icon {
    padding-top: utils.toRem(3.2px);
  }
}

.aprm-skeleton {
  &__item:not(:last-child) {
    margin-bottom: utils.toRem(16px);
  }
}
