@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/layout';

.program-hero {
  margin-bottom: utils.toRem(32px);
  padding: utils.toRem(32px) utils.toRem(16px);
  background-color: colors.$surface-color-active;

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(40px);
    padding: utils.toRem(48px) 0;
  }

  &__text {
    @include hero.hero-text;
  }

  &__wrapper {
    @include layout.container-md;
  }

  &__row-wrapper {
    width: 100%;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(48px);
    }
  }

  &__main-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      min-height: utils.toRem(116px);
    }
  }

  &__left-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      flex-grow: 1;
    }
  }

  &__icon {
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin: 0 utils.toRem(24px) 0 0;
    }
  }

  &__left-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__line {
    @include hero.hero-line-divider;
  }

  &__right-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include breakpoints.query('>=md') {
      align-items: center;
      flex-grow: 1;
    }
  }

  &__text-row-wrapper {
    display: inline-flex;
  }

  &__dollar {
    display: none;

    @include breakpoints.query('>=lg') {
      display: block;
      fill: colors.$white;
      width: utils.toRem(24px);
      height: utils.toRem(24px);
      margin-right: utils.toRem(8px);
    }
  }

  &__loading {
    background-color: colors.$loading-color-state;

    &--subtitle-left {
      width: math.percentage(math.div(200px, 429.5px));
      height: utils.toRem(24px);
    }

    &--subtitle-right {
      width: math.percentage(math.div(463.5px, 495.5px));
      height: utils.toRem(24px);
    }

    &--heading-right {
      width: math.percentage(math.div(180px, 495.5px));
      height: utils.toRem(40px);
      margin-bottom: utils.toRem(16px);
    }
  }
}
