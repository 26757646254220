@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.table-row {
  &__cell-wrap {
    display: flex;
  }

  &__program-icon {
    margin-right: utils.toRem(8px);
  }
}

.table-row-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    flex-direction: row;
    padding: utils.toRem(24px);
  }
}

.order-details {
  border-bottom: variables.$standard-border;
  margin-bottom: utils.toRem(16px);
  padding-bottom: utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    padding: utils.toRem(16px);
    border: variables.$standard-border;
    flex-basis: calc(50% - #{utils.toRem(16px)});
    margin-bottom: 0;
  }

  &__header {
    @include breakpoints.query('>=lg') {
      display: flex;
      justify-content: space-between;
    }
  }

  &__title {
    @include typekit.font-subtitle-2-bold;
    display: block;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=lg') {
      @include typekit.font-body-1-bold;
      margin-bottom: utils.toRem(4px);
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    display: block;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=lg') {
      margin-bottom: 0;
    }
  }

  &__divider {
    margin: utils.toRem(16px) 0;
    border: none;
    height: 1px;
    background-color: colors.$surface-color-stroke;

    @include breakpoints.query('>=lg') {
      margin: utils.toRem(24px) 0;
    }
  }

  &__order-help {
    display: flex;
    align-items: center;
    margin-bottom: utils.toRem(8px);
    line-height: utils.toRem(28px);

    @include breakpoints.query('>=lg') {
      margin-bottom: 0;
    }
  }

  &__tool-tip {
    word-break: break-word;
  }

  &__tooltip-icon {
    width: utils.toRem(16px);
    margin-left: utils.toRem(8px);
  }

  &__purchaser {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);
    margin-bottom: utils.toRem(16px);
  }
}

.product-list {
  &__header,
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: utils.toRem(8px);
  }

  &__heading {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=lg') {
      @include typekit.font-caption;
    }
  }

  &__item-text {
    @include typekit.font-body-2;

    &--w-color {
      color: colors.$surface-color-active;
    }
  }
}

.rewards-earned {
  flex-basis: calc(50% - #{utils.toRem(16px)});

  &__title {
    @include typekit.font-body-2-bold;
    display: block;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=lg') {
      @include typekit.font-body-1-bold;
    }
  }
}

.rewards-program {
  margin-bottom: utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    margin-bottom: utils.toRem(24px);
  }

  &__title {
    @include typekit.font-overline;
    display: block;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=lg') {
      @include typekit.font-caption;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-bottom: utils.toRem(8px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__icon {
    margin-right: utils.toRem(16px);
  }

  &__text {
    @include typekit.font-body-2;

    &--w-color {
      color: colors.$surface-color-active;
    }
  }

  &__button-wrapper {
    @include breakpoints.query('>=lg') {
      display: flex;
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=lg') {
      width: auto;
    }

    &:first-child {
      margin-bottom: utils.toRem(8px);

      @include breakpoints.query('>=lg') {
        margin-right: utils.toRem(16px);
        margin-bottom: 0;
      }
    }
  }
}

.crop-protection-section {
  margin-bottom: utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    margin-bottom: utils.toRem(24px);
  }

  &__title {
    @include typekit.font-body-2-bold;
    display: block;
    margin-bottom: utils.toRem(8px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=lg') {
      @include typekit.font-body-1-bold;
      margin-bottom: utils.toRem(4px);
    }
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=lg') {
      @include typekit.font-body-1;
    }
  }
}

.purchaser-section {
  &__cell {
    display: flex;
    justify-content: center;
  }

  &__tooltip {
    width: fit-content;
    white-space: nowrap;
  }
}

.mobile-cell {
  display: flex;
  gap: utils.toRem(16px);

  &__title {
    @include typekit.font-body-2-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    display: block;
  }

  &__date-programs-wrap {
    display: flex;
    align-items: center;
    margin-bottom: utils.toRem(8px);
  }

  &__date,
  &__retailer {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
  }

  &__middle-dot {
    margin: 0 utils.toRem(8px);
  }
}
