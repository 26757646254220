@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.banner-skeleton {
  width: 100%;
  margin-bottom: utils.toRem(48px);
  padding: utils.toRem(32px);
  background-color: colors.$surface-color-variant;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(48px);
  }

  &__content-wrapper {
    width: 100%;
    background-color: colors.$white;
    padding: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      width: math.percentage(math.div(564px, 1120px));
      padding: utils.toRem(31px) utils.toRem(32px);
    }
  }

  &__top-block {
    background-color: colors.$skeleton-mask;

    &:not(:last-of-type) {
      margin-bottom: utils.toRem(16px);
    }

    &--heading {
      width: 100%;
      height: utils.toRem(80px);
    }

    &--subtitle {
      width: math.percentage(math.div(564px, 1120px));
      height: utils.toRem(20px);
    }
  }

  &__divider {
    width: 100%;
    color: colors.$surface-color-stroke;
    margin: utils.toRem(24px) 0;
  }

  &__bottom-block {
    background-color: colors.$skeleton-mask;

    &:not(:last-of-type) {
      margin-bottom: utils.toRem(16px);
    }

    &--block {
      width: 100%;
      height: utils.toRem(56px);
    }

    &--single-row {
      width: 100%;
      height: utils.toRem(12px);
    }

    &--short-row {
      width: math.percentage(math.div(300px, 564px));
      height: utils.toRem(12px);
    }
  }
}

.widget-skeleton {
  &__inner {
    height: 100%;
    padding-top: utils.toRem(24px);
    border-top: variables.$standard-border;
  }

  &__rewards-title {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__rewards-subtitle {
    background-color: colors.$skeleton-mask;
    height: utils.toRem(25px);
    width: math.percentage(math.div(350px, 525px));
    margin-top: utils.toRem(16px);
  }

  &__progress-bar {
    background-color: colors.$skeleton-mask;
    margin-top: utils.toRem(16px);
    width: 100%;
    height: utils.toRem(56px);
  }

  &__link-button {
    background-color: colors.$skeleton-mask;
    width: math.percentage(math.div(175px, 525px));
    height: utils.toRem(20px);
    margin-top: utils.toRem(26px);
  }
}
