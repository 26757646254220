@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.cfb {
  @include breakpoints.query('>=md') {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }

  &__loading {
    background-color: colors.$white;
    padding: utils.toRem(100px);
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
    }
  }

  &__description {
    @include typekit.font-body-2;
    margin-bottom: utils.toRem(24px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__text-wrapper {
    @include breakpoints.query('>=md') {
      flex-basis: math.percentage(math.div(538px, 960px));
    }
  }

  &__icon-wrapper {
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__icon {
    width: utils.toRem(195px);
  }

  &__price-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: utils.toRem(40px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(48px);
    }
  }

  &__price {
    display: inline-block;
    color: colors.$surface-color-inactive-med;

    &--big {
      @include typekit.font-display-6;
      margin-right: utils.toRem(8px);
    }

    &--small {
      @include typekit.font-caption;
      text-decoration-line: line-through;
    }
  }

  &__image-wrapper {
    margin: 0 auto utils.toRem(32px) auto;
    width: math.percentage(math.div(159px, 256px));
  }

  &__image {
    width: 100%;
  }
}

.cfb-dashboard {
  background-color: colors.$white;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    justify-content: flex-end;
    padding: utils.toRem(24px);
    border: variables.$standard-border;
  }

  &__image-wrapper {
    flex-shrink: 0;

    @include breakpoints.query('>=md') {
      margin: 0;
      margin-right: utils.toRem(64px);
      width: math.percentage(math.div(315px, 1175px));
    }

    @include breakpoints.query('>=lg') {
      margin-right: utils.toRem(80px);
      width: math.percentage(math.div(245px, 1175px));
      max-width: utils.toRem(245px);
    }
  }
}

.cfb-rewards {
  background-color: colors.$surface-color-variant;
  padding: utils.toRem(24px) utils.toRem(16px);

  @include breakpoints.query('>=md') {
    justify-content: space-between;
    padding: utils.toRem(40px) utils.toRem(80px);
  }

  &__image-wrapper {
    @include breakpoints.query('>=md') {
      margin: 0;
      width: math.percentage(math.div(302px, 960px));
    }
  }
}
