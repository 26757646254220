@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.card-link {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: transparent;
  transition: background-color 300ms ease-in-out;
  padding: utils.toRem(20px);

  @include breakpoints.query('>=lg') {
    padding: utils.toRem(16px) utils.toRem(20px);
  }

  &--is-header {
    @include breakpoints.query('>=lg') {
      padding: utils.toRem(20px);
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid transparent;
    transition: border-color 300ms ease-in-out, border-width 300ms ease-in-out;
  }

  &:hover {
    background-color: colors.$surface-color-variant;
  }

  &:focus {
    outline: none;
    background-color: colors.$surface-color-variant;

    &::before {
      border-width: utils.toRem(4px);
      border-color: colors.$secondary-color-400;
    }
  }

  &:active {
    background-color: colors.$surface-color-variant;

    &::before {
      border-width: utils.toRem(1px);
      border-color: colors.$surface-color-active;
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: colors.$surface-color-active;
  }

  &__left,
  &__right {
    display: flex;
    align-items: center;
  }

  &__left {
    margin-right: utils.toRem(16px);
  }

  &__right {
    color: colors.$surface-color-active;

    &--with-text {
      color: colors.$primary-color-400;
    }
  }

  &__card-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
    margin-right: utils.toRem(18px);
    flex-shrink: 0;
  }

  &__heading {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=lg') {
      @include typekit.font-subtitle-1;
    }

    &--bold {
      @include typekit.font-subtitle-1-bold;

      @include breakpoints.query('>=xl') {
        @include typekit.font-display-6;
      }
    }
  }

  &__link-text {
    @include typekit.font-button;
    margin-right: utils.toRem(8px);
  }

  &__icon-wrapper {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
  }

  &__icon {
    width: inherit;
    height: inherit;
    fill: currentColor;
  }
}
