@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/abstracts/utils';

.bayer-rewards-landing {
  width: 100%;

  &__divider {
    @include layout.page-section-divider;
  }
}
