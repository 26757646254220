@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use '../../Setup.module';

.farm-information {
  &__radio-group {
    padding: utils.toRem(18px) utils.toRem(16px);
    border: variables.$standard-border;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__radio {
    padding: 0;
  }

  &__preference {
    margin-top: utils.toRem(28px);

    & .form-row:last-child .form-input:last-child {
      margin-bottom: 0;
    }

    &--hidden {
      display: none;
    }

    @include breakpoints.query('>=md') {

      &>*:last-child .form-input {
        margin-bottom: 0;
      }
    }
  }

  &__header {
    flex-direction: column;
  }

  &__heading {
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      margin-bottom: 0;
    }
  }

  &__body {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
    padding: 0 utils.toRem(8px);
    margin-bottom: utils.toRem(8px);
  }

  &__subtitle {
    color: colors.$surface-color-inactive-med;
  }

  &__banner {
    margin-bottom: utils.toRem(24px);
    &--last {
      margin: utils.toRem(24px) 0 0 0;
    }
  }
}