@use '../abstracts/colors';

@mixin validationStateFill {
  &--validation-green {
    fill: colors.$success-green;
  }

  &--surface-color-inactive-med {
    fill: colors.$surface-color-inactive-med;
  }
}

@mixin validationStateColors {
  &--validation-green {
    color: colors.$success-green;
  }

  &--primary-color-400 {
    color: colors.$primary-color-400;
  }
}
