@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';

.profile {
  margin-top: utils.toRem(24px);

  &__form-header {
    margin-bottom: utils.toRem(32px);
  }

  &__form-title-wrapper {
    margin-bottom: utils.toRem(32px);
  }

  &__form-title {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__form-subtitle {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__form-group {
    &__title {
      @include typekit.font-body-2;
      width: 100%;
      color: colors.$surface-color-active;
      margin-bottom: utils.toRem(16px);

      @include breakpoints.query('>=md') {
        @include typekit.font-subtitle-1;
        margin-bottom: utils.toRem(16px);
      }
    }

    &__sub-title {
      @include typekit.font-body-2;
      color: colors.$surface-color-inactive-med;
      display: block;
    }
  }

  &__deactivate-account {
    margin-bottom: utils.toRem(24px);
  }

  &__row {
    display: flex;
  }

  &__icon-wrapper {
    color: colors.$danger-color-700;
  }

  &__icon {
    fill: currentColor;
  }

  &__heading {
    margin-left: utils.toRem(21px);
  }

  &__heading-title {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
    }
  }

  &__heading-subtitle {
    @include typekit.font-body-2;
    margin-top: utils.toRem(10px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__button {
    margin-top: utils.toRem(10px);
  }
}
