@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/typekit';


.step-wrapper {
  max-width: utils.toRem(432px + 32px);
  width: 100%;
  margin: 0 auto;
  padding: utils.toRem(24px) utils.toRem(16px);

  @include breakpoints.query('>=lg') {
    max-width: utils.toRem(432px);
    align-items: flex-start;
    padding: 0;
  }

  &__header {
    margin-bottom: utils.toRem(24px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$secondary-color-900;
    margin-bottom: utils.toRem(8px);
  }

  &__icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    background-color: colors.$secondary-color-900;
    border-radius: utils.toRem(100px);
    margin-bottom: utils.toRem(24px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    color: colors.$white;
  }
}
