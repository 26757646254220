@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.banner-badge {
  @include typekit.font-overline;
  display: flex;
  align-items: center;
  gap: utils.toRem(4px);
  width: fit-content;
  height: auto;
  padding: utils.toRem(4px) utils.toRem(24px);
  background-color: colors.$success-green;
  color: colors.$white;
  clip-path: polygon(10px 0%, 100% 0%, calc(100% - 10px) 100%, 0% 100%);

  &__icon {
    padding: utils.toRem(1px);
    height: utils.toRem(16px);
    width: utils.toRem(16px);
    flex-shrink: 0;
  }

  &__text {
    padding: 0 utils.toRem(8px);
  }
}
