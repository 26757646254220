@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.crop-information {
  width: 100%;

  &__heading {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
  }

  &__field-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      padding-right: utils.toRem(69px);
    }
  }

  &__field {
    margin-top: utils.toRem(24px);
    width: 100%;

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(16px);
      min-width: utils.toRem(322px);
      max-width: 50%;
      flex-grow: 1;
    }

    &--custom {
      margin-top: utils.toRem(24px);

      @include breakpoints.query('>=md') {
        margin-top: utils.toRem(16px);
        margin-left: utils.toRem(16px);
      }
    }

    &--multiple {
      @include breakpoints.query('>=md') {
        max-width: 100%;
      }
    }
  }

  &__line {
    margin: utils.toRem(24px) 0 utils.toRem(16px) 0;
    border: none;
    height: 1px;
    background-color: colors.$surface-color-stroke;

    @include breakpoints.query('>=md') {
      margin: utils.toRem(32px) 0;
    }
  }

  &__crops-wrapper {
    margin-top: utils.toRem(16px);
    display: grid;
    grid-template-columns: 1fr;

    &--grains {
      margin-bottom: utils.toRem(24px);
    }

    @include breakpoints.query('>=md') {
      grid-template-columns: repeat(3, minmax(10px, 1fr));
      grid-auto-flow: row;
    }
  }
}
