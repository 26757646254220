@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.banners-section {
  @include layout.container-md;
  padding-top: utils.toRem(32px);
  padding-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(48px);
    padding-bottom: utils.toRem(48px);
  }

  &__text-wrapper {
    margin-bottom: utils.toRem(32px);
    text-align: center;

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(40px);
    }
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(577px);
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__climate-field-view-wrapper {
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(40px);
    }
  }
}
