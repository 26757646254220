@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.partner-permissions {
  display: flex;
  height: inherit;
  align-items: flex-start;
  flex-direction: column;

  &__subheading {
    @include typekit.font-body-1;
    display: flex;
    align-items: center;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__user-description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(8px);
  }

  &__user-no-partners {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__user-wrapper {
    margin-top: utils.toRem(24px);
    width: 100%;
  }

  &__icon {
    fill: colors.$surface-color-inactive-med;
    width: utils.toRem(18px);
    height: utils.toRem(18px);
  }

  &__tooltip {
    margin-left: utils.toRem(8px);
  }
}
