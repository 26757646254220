@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.purchases-widget {
  background-color: colors.$white;

  @include breakpoints.query('>=md') {
    border: variables.$standard-border;
  }

  @include breakpoints.query('>=lg') {
    height: 100%;
  }

  &__table-wrapper {
    border-top: variables.$standard-border;

    @include breakpoints.query('>=lg') {
      padding: utils.toRem(12px) utils.toRem(24px);
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  &__cell {
    padding: utils.toRem(12px);

    &:first-child {
      @include breakpoints.query('<lg') {
        padding-left: utils.toRem(24px);
      }
    }

    &:last-child {
      @include breakpoints.query('<lg') {
        padding-right: utils.toRem(24px);
      }
    }

    @include breakpoints.query('>=lg') {
      padding: utils.toRem(12px) 0;
    }
  }

  &__table-heading {
    @include typekit.font-overline;
    text-align: left;
    color: colors.$surface-color-inactive-med;

    &--greedy {
      width: 100%;
    }

    &--loading {
      @include breakpoints.query('>=lg') {
        padding-bottom: 0;
      }
    }
  }

  &__badges-wrapper {
    display: flex;
    align-items: center;
  }

  &__badge {
    &:not(:last-child) {
      margin-right: utils.toRem(8px);
    }
  }

  &__row {
    border-bottom: variables.$standard-border;

    &:last-child:not(:only-child) {
      border-bottom: none;
    }

    &--loading {
      @include breakpoints.query('>=lg') {
        border-bottom: none;
      }
    }
  }

  &__product-name {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
  }
}
