@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.search-bar {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: utils.toRem(56px);

  &__input {
    appearance: none;
    width: 100%;
    height: inherit;
    @include typekit.font-body-1;
    outline: 1px solid colors.$surface-color-inactive-low;
    border-radius: 0;
    border-width: 0px;
    padding: utils.toRem(14px) utils.toRem(56px) utils.toRem(14px) utils.toRem(16px);
    transition: outline 300ms ease-in-out;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &:hover {
      outline: 1px colors.$surface-color-active solid colors.$surface-color-active;
    }

    &:focus,
    &:active {
      outline: variables.$active-border;
    }

    &:not(:placeholder-shown) {
      color: colors.$surface-color-active;
      outline: 1px solid colors.$surface-color-inactive-low;
    }
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: utils.toRem(16px);
    margin-right: utils.toRem(16px);
    width: utils.toRem(24px);
    fill: colors.$surface-color-inactive-med;
  }
}
