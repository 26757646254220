@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.partner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: utils.toRem(16px);
  margin-bottom: utils.toRem(16px);
  margin-right: utils.toRem(16px);
  border: variables.$standard-border;

  @include breakpoints.query('>=md') {
    flex-direction: row;
    align-items: center;
    max-width: utils.toRem(729px);
    margin-right: 0;
  }

  &__mobile-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include breakpoints.query('>=md') {
      display: inline;
    }

    &--checkmark {
      width: utils.toRem(18px);
      height: utils.toRem(18px);
      fill: colors.$success-green;

      @include breakpoints.query('>=md') {
        display: none;
      }
    }
  }

  &__details-wrapper {
    display: flex;
    flex-direction: column;

    &--name {
      @include typekit.font-subtitle-1;
      color: colors.$surface-color-active;
    }

    &--email {
      @include typekit.font-body-1;
      color: colors.$surface-color-inactive-med;
    }
  }

  &__button {
    width: 100%;
    margin-top: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      width: auto;
      margin-top: 0;
    }

    &--disabled {
      pointer-events: none;
    }
  }
}

.access-granted {
  display: flex;
  align-items: center;
  width: auto;

  @include breakpoints.query('>=md') {
    flex-direction: row;
  }

  &__desktop-wrapper {
    display: none;

    @include breakpoints.query('>=md') {
      display: flex;
      width: 100%;
    }
  }

  &__text {
    display: none;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-2;
      display: inline;
      color: colors.$surface-color-active;
    }
  }

  &__checkmark {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    fill: colors.$success-green;

    @include breakpoints.query('>=md') {
      margin-left: utils.toRem(8px);
      margin-right: utils.toRem(16px);
    }
  }
}

.loading-icon {
  @include animations.spinner-rotate-left-animation;
}

.modal {
  &__subtitle {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    margin-top: utils.toRem(24px);
  }

  &__text {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(24px);
  }
}
