@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.dashboard {
  width: 100%;
}

.dm {
  &__dark-section {
    @include layout.container-md;
    padding-top: utils.toRem(32px);
    padding-bottom: utils.toRem(32px);
    background-color: colors.$secondary-color-900;
  }

  &__hr {
    height: utils.toRem(8px);
    background-color: colors.$surface-color-variant;
    border: none;
    width: 100%;
    margin: 0;
  }

  &__members-wrapper {
    margin-bottom: utils.toRem(16px);
  }
}

.dd {
  background-color: colors.$surface-color-variant;

  &__container {
    display: flex;
  }

  &__sidebar {
    width: utils.toRem(352px);
    background-color: colors.$secondary-color-900;
    padding: utils.toRem(48px) utils.toRem(32px) utils.toRem(32px);
    flex-shrink: 0;
  }

  &__welcome-wrapper {
    margin-bottom: utils.toRem(32px);
  }

  &__take-action-wrapper {
    margin-bottom: utils.toRem(32px);
  }

  &__farm-members-wrapper {
    margin-bottom: utils.toRem(32px);
  }

  &__main {
    flex-grow: 1;
    background-color: colors.$surface-color-variant;
    padding: utils.toRem(32px);
  }

  &__grid {
    display: grid;
    grid-template-columns: math.percentage(math.div(573px, 1024px)) math.percentage(
        math.div(433px, 1024px)
      );
    gap: utils.toRem(24px);
  }

  &__rewards-wrapper {
    grid-column: 1;
    grid-row: 1;

    &--trip {
      grid-row: 1 / span 2;
    }
  }

  &__purchases-wrapper {
    grid-column: 2;
    grid-row: 1;

    &--trip {
      grid-row: 1 / span 2;
    }
  }

  &__calculator-wrapper {
    &--trip {
      grid-column: 2;
    }

    &--no-access {
      grid-column: 1/-1;
    }
  }
}

.dt {
  &__dark-section {
    @include layout.container-md;
    max-width: none;
    padding-top: utils.toRem(32px);
    padding-bottom: utils.toRem(32px);
    background-color: colors.$secondary-color-900;
  }

  &__middle-section {
    @include layout.container-md;
    max-width: none;
    padding-top: utils.toRem(32px);
    padding-bottom: utils.toRem(32px);
    background-color: colors.$surface-color-variant;

    @include breakpoints.query('>=lg') {
      padding-top: utils.toRem(24px);
      padding-bottom: utils.toRem(24px);
    }
  }

  &__take-action-wrapper {
    margin-bottom: utils.toRem(24px);
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: utils.toRem(24px);

    @include breakpoints.query('>=lg') {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__rewards-wrapper {
    @include breakpoints.query('>=lg') {
      grid-column: 1;
      grid-row: 1;

      &--trip {
        grid-row: 1 / span 2;
      }
    }
  }

  &__purchases-wrapper {
    @include breakpoints.query('>=lg') {
      grid-column: 2;
      grid-row: 1;

      &--trip {
        grid-row: 1 / span 2;
      }
    }
  }

  &__calculator-wrapper {
    &--trip {
      grid-row: 2;
      grid-column: 2;
    }

    &--no-access {
      grid-column: 1/-1;
    }
  }

  &__bottom-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: utils.toRem(16px);
    padding-bottom: utils.toRem(8px);
  }
}

.ds {
  &__banner-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: utils.toRem(24px);
  }

  &__banner {
    &:not(:last-child) {
      margin-bottom: utils.toRem(24px);
    }

    &--hidden {
      display: none;
    }
  }
}
