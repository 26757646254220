@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.program-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  flex-shrink: 0;

  &--sm {
    height: utils.toRem(24px);
    width: utils.toRem(24px);
  }

  &--md {
    height: utils.toRem(32px);
    width: utils.toRem(32px);
  }

  &--lg {
    height: utils.toRem(40px);
    width: utils.toRem(40px);
  }

  &__icon {
    &--sm {
      height: utils.toRem(13.5px);
      width: utils.toRem(13.5px);
    }

    &--md {
      height: utils.toRem(20px);
      width: utils.toRem(20px);
    }

    &--lg {
      height: utils.toRem(24px);
      width: utils.toRem(24px);
    }

    &--dark {
      fill: colors.$surface-color-active;
      color: colors.$surface-color-active;
    }

    &--light {
      fill: colors.$white;
      color: colors.$white;
    }
  }
}
