@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/animations';

.primary-owner {
  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: utils.toRem(32px);
  }

  &__inner {
    width: 100%;
    max-width: calc(utils.toRem(432px) + utils.toRem(64px));
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(8px);
    }
    margin-bottom: utils.toRem(16px);
  }

  &__sub-heading {
    display: block;
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);
  }

  &__button {
    width: 100%;
    margin-bottom: utils.toRem(24px);

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__login {
    @include typekit.font-body-2;
  }

  &__forgot {
    @include typekit.font-body-2;
    margin-bottom: utils.toRem(8px);
    padding-inline: 0;
  }
}

.forgot-code {
  &__description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__list {
    list-style: inside;
    list-style-type: decimal;
  }
}
