@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__arrow {
    width: utils.toRem(32px);
    height: utils.toRem(32px);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid transparent;
    border-radius: utils.toRem(40px);
    outline: none;
    background-color: transparent;
    cursor: pointer;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
      border-color 300ms ease-in-out;

    &:hover {
      background-color: colors.$primary-color-state-01;
    }

    &:focus {
      background-color: colors.$primary-color-state-01;
      border-color: colors.$secondary-color-400;
    }

    &:active {
      background-color: colors.$primary-color-state-02;
      border-color: transparent;
    }

    &:disabled {
      pointer-events: none;
      color: colors.$surface-color-inactive-low;
    }

    &--left {
      margin-right: utils.toRem(12px);
    }

    &--right {
      margin-left: utils.toRem(12px);
    }
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$primary-color-400;
    transition: fill 300ms ease-in-out;

    &--disabled {
      fill: colors.$surface-color-inactive-low;
    }
  }

  &__button {
    @include typekit.font-body-2;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: utils.toRem(32px);
    height: utils.toRem(32px);
    border: 4px solid transparent;
    border-radius: utils.toRem(40px);
    background-color: transparent;
    color: colors.$secondary-color-900;
    outline: none;
    transition: color 300ms ease-in-out, background-color 300ms ease-in-out,
      border-color 300ms ease-in-out;

    &:hover {
      background-color: colors.$surface-color-variant;
      text-decoration: underline;
    }

    &:focus {
      background-color: colors.$surface-color-variant;
      border-color: colors.$secondary-color-400;
      text-decoration: underline;
    }

    &:active {
      background-color: colors.$surface-color-stroke;
      text-decoration: underline;
    }

    &:disabled {
      pointer-events: none;
      color: colors.$surface-color-disabled;
    }

    &:not(:last-child) {
      margin-right: utils.toRem(16px);
    }

    &--current,
    &--current:hover,
    &--current:focus-within,
    &--current:active {
      background-color: colors.$secondary-color-900;
      color: colors.$white;
    }
  }

  &__dots {
    margin-right: utils.toRem(16px);
    color: colors.$surface-color-disabled;
  }

  &__buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
