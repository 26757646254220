@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.signup-complete {

  &__header {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    text-align: left;
  }

  &__details {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
    text-align: left;
    margin-bottom: utils.toRem(24px);
  }

  &__sub-details {
    @include typekit.font-subtitle-2;
    margin-top: utils.toRem(24px);
    text-align: left;
    color: colors.$surface-color-inactive-med;
    display: block;
  }
}
