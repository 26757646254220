@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';

.program-promotion-section {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(8px);

  &--widget {
    background-color: colors.$surface-color-variant;

    @include breakpoints.query('>=md') {
      gap: utils.toRem(24px);
    }
  }

  &--banner {
    @include layout.container-md;
    gap: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      padding-block: utils.toRem(64px);
    }
  }

  &__button-loading {
    @include animations.spinner-rotate-left-animation;
  }

  &__divider {
    @include layout.page-section-divider;
  }
}
