@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.preauth-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: colors.$secondary-color-700;
  z-index: variables.$z-index-modal-footer;

  &__inner {
    @include layout.container-md;
    padding: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      height: utils.toRem(40px);
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      padding: 0 utils.toRem(24px);
    }

    @include breakpoints.query('>=lg') {
      grid-template-columns: 1fr 1.5fr 1fr;
    }
  }

  &__copyright-wrapper {
    @include breakpoints.query('>=md') {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__links-wrapper {
    @include breakpoints.query('>=md') {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &__copyright {
    @include typekit.font-caption;
    color: colors.$white;
  }

  &__nav {
    padding-bottom: utils.toRem(16px);
    @include breakpoints.query('>=md') {
      padding-bottom: 0;
    }
  }

  &__list {
    @include breakpoints.query('>=md') {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__nav-item {
    @include typekit.font-caption;

    @include breakpoints.query('>=lg') {
      flex-shrink: 0;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);

      @include breakpoints.query('>=md') {
        margin-bottom: 0;
        padding-right: utils.toRem(10px);
        position: relative;
      }

      &:after {
        @include breakpoints.query('>=md') {
          content: '/';
          color: colors.$white;
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__link {
    @include typekit.font-caption;
    padding: 0;
    border: 0;
  }
}