@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.take-action-widget {
  background-color: colors.$white;

  &__text-wrapper {
    padding: utils.toRem(12px) utils.toRem(16px);
  }

  &__title {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
  }

  &__subtitle {
    @include typekit.font-body-2;
    margin-top: utils.toRem(4px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=xl') {
      @include typekit.font-body-1;
    }
  }

  &__list-item {
    border-top: 1px solid rgba(16, 56, 79, 0.1215686275);
  }
}
