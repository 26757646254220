@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.snackbar {
  padding: utils.toRem(4px) utils.toRem(8px);
  display: flex;
  flex-direction: row;
  align-items: center;
  width: utils.toRem(272px);

  @include breakpoints.query('>=md') {
    width: utils.toRem(380px);
    min-width: utils.toRem(344px);
  }

  &--error {
    background-color: colors.$danger-color-700;
    border: 4px solid transparent;
    transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms;

    &:hover {
      background-color: colors.$danger-color-400;
    }

    &:focus {
      border-color: colors.$secondary-color-400;
    }
  }

  &--success {
    background-color: colors.$snackbar-success;
    border: 4px solid transparent;
    transition: background-color ease-in-out 300ms, border-color ease-in-out 300ms;

    &:hover {
      background-color: colors.$black;
    }

    &:focus {
      border-color: colors.$secondary-color-400;
    }
  }

  &__text {
    @include typekit.font-subtitle-2-bold;
    color: colors.$white;
    margin-left: utils.toRem(8px);
    padding-right: utils.toRem(31px);
    flex-grow: 1;

    @include breakpoints.query('>=md') {
      padding-right: utils.toRem(24px);
    }
  }

  &__icon {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    fill: colors.$white;
    flex-shrink: 0;
  }

  &__button {
    cursor: pointer;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }
}
