@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.form-header {
  display: flex;
  flex-direction: column-reverse;
  gap: utils.toRem(24px);
  margin-bottom: utils.toRem(10px);

  @include breakpoints.query('>=md') {
    gap: 0;
    flex-direction: column;
    margin-bottom: utils.toRem(40px);
  }
}

.form-name {
  @include typekit.font-subtitle-2;
  color: colors.$surface-color-active;
  margin-bottom: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    @include typekit.font-subtitle-1;
  }
}

.form-row {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  @include breakpoints.query('>=md') {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    column-gap: utils.toRem(32px);
  }
}

.form-nested-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: utils.toRem(16px);
}

.form-input {
  margin-bottom: utils.toRem(24px);

  &--error {
    margin-bottom: utils.toRem(6px);
  }

  &--dropdown {
    margin-bottom: 0;
  }
}

.form-divider {
  background-color: colors.$surface-color-stroke;
  margin: 0;
  margin-bottom: utils.toRem(24px);
  border: none;
  height: 1px;

  &--invisible {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
      margin: 0;
      margin-bottom: utils.toRem(20px);
    }
  }
}

.form-group {
  &__title {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: rgba($color: colors.$surface-color-active, $alpha: 0.7);
    display: block;
  }
}

.form-preferences {
  padding: 0 utils.toRem(16px);
}

.form-preference {
  padding: utils.toRem(12px) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__radio {
    padding: 0;
  }
}

.form-preference-info {
  margin-right: utils.toRem(16px);

  &__title {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: rgba($color: colors.$surface-color-active, $alpha: 0.7);
    display: block;
  }
}

.info-icon {
  fill: colors.$surface-color-active;
}
