@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.keys {
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  width: fit-content;

  @include breakpoints.query('>=md') {
    border: variables.$standard-border;
    border-radius: 4px;
    padding: utils.toRem(16px);
    align-items: center;
    flex-direction: row;
  }

  &__text {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-right: utils.toRem(16px);
      margin-bottom: 0;
    }
  }

  &__program {
    display: flex;
    align-items: center;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-right: utils.toRem(40px);
      margin-bottom: 0;
    }

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &__program-icon {
    margin-right: utils.toRem(16px);
  }

  &__program-text {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
  }
}
