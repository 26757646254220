@use 'sass:math';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.summer-sizzler-widget {
  display: grid;
  grid-template-columns: 1fr;
  gap: utils.toRem(24px);
  padding: utils.toRem(16px);
  background-color: colors.$white;
  border: variables.$standard-border;

  @include breakpoints.query('>=md') {
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding: utils.toRem(24px);
  }

  &__container {
    grid-column: 1;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    height: auto;
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(24px);
  }

  &__description-wrapper {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
  }

  &__button {
    grid-row: 3;

    @include breakpoints.query('>=md') {
      margin-top: auto;
      width: fit-content;
      grid-column: 1;
      grid-row: 2;
    }
  }

  &__image-container {
    grid-row: 2;
    width: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      grid-column: 2;
      grid-row: 1 / span 2;
      height: 100%;
      align-items: center;
    }

    @include breakpoints.query('>=lg') {
      flex-direction: row;
      justify-content: end;
    }
  }

  &__image {
    width: 100%;
    
    @include breakpoints.query('>=md') {
      width: utils.toRem(277px);
      height: utils.toRem(164px);
    }
  }
}
