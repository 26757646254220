@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.accordion-item {
  border-bottom: variables.$standard-border;

  &__title {
    @include typekit.font-subtitle-2-bold;
    color: colors.$surface-color-active;
    text-align: left;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
    }
  }

  &__toggle {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: utils.toRem(20px) utils.toRem(16px);
    width: 100%;
    transition: background-color 300ms ease-in-out;

    &::before {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid transparent;
      transition: border-color 300ms ease-in-out, border-width 300ms ease-in-out;
    }

    &:hover {
      background-color: colors.$surface-color-variant;
    }

    &:focus {
      outline: none;
      background-color: colors.$surface-color-variant;

      &::before {
        border-width: utils.toRem(4px);
        border-color: colors.$secondary-color-400;
      }
    }

    &:active {
      background-color: colors.$surface-color-variant;

      &::before {
        border-width: utils.toRem(1px);
        border-color: colors.$surface-color-active;
      }
    }
  }

  &__icon {
    height: utils.toRem(24px);
    margin: 0 utils.toRem(10px);
    fill: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      margin: 0 utils.toRem(20px);
    }
  }

  &__content {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
  }

  &__content-inner {
    padding: utils.toRem(16px);
  }

  &--active {
    position: relative;
    border: variables.$standard-border;

    &:not(:first-of-type) {
      border-top: none;
    }

    .accordion-item {
      &__toggle {
        background-color: colors.$surface-color-variant;
      }

      &__icon {
        transform: rotate(180deg);
      }
    }
  }
}
