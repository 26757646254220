@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.promotion-banner {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(24px);
  padding: utils.toRem(16px);
  background-color: colors.$white;

  &--banner {
    padding: utils.toRem(24px) 0;
  }

  &--widget {
    border: variables.$standard-border;
  }

  @include breakpoints.query('>=md') {
    flex-direction: row;
    min-height: utils.toRem(250px);

    &--banner {
      gap: utils.toRem(64px);
    }
  }

  &__image {
    width: 100%;
    height: utils.toRem(180px);
    object-fit: cover;
    object-position: right;
    flex-shrink: 0;
    align-self: stretch;

    @include breakpoints.query('>=md') {
      height: auto;
      width: utils.toRem(300px);

      &--banner {
        width: utils.toRem(400px);
      }
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
  }

  &__description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    white-space: pre-line;
  }

  &__button {
    @include breakpoints.query('>=md') {
      margin-top: auto;
      width: fit-content;
    }
  }
}
