@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/typekit';

.my-operation {
  padding-top: utils.toRem(24px);
  padding-bottom: utils.toRem(24px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(32px);
    padding-bottom: 0;
  }

  &__text-wrapper {
    margin-bottom: utils.toRem(32px);
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__subtitle {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__radio-group {
    padding: utils.toRem(18px) utils.toRem(16px);
    border: variables.$standard-border;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__radio {
    padding: 0;
  }

  &__preference {
    margin-top: utils.toRem(28px);

    & .form-row:last-child .form-input:last-child {
      margin-bottom: 0;
    }

    &--hidden {
      display: none;
    }

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(44px);

      & > *:last-child .form-input {
        margin-bottom: 0;
      }
    }
  }

  &__farm-card {
    border: variables.$standard-border;
  }

  &__address-banner {
    margin-bottom: utils.toRem(24px);
  }
}

.tor-notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px colors.$surface-color-stroke;
  background-color: colors.$secondary-color-state-01;
  color: colors.$surface-color-active;
  padding: utils.toRem(16px);
  margin-top: utils.toRem(16px);

  &__text-container {
    display: flex;
    align-items: center;
    margin-right: utils.toRem(8px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    flex-shrink: 0;
    margin-right: utils.toRem(8px);
  }
}

.tor-modal {
  &__title {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
  }

  &__text {
    @include typekit.font-subtitle-1;
    margin-bottom: utils.toRem(16px);
    color: colors.$surface-color-inactive-med;
  }

  &__loading {
    @include animations.spinner-rotate-left-animation;
  }
}
