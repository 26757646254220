@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';

.delkab-promotion {
  &__image {
    object-fit: scale-down;
    object-position: center;
    flex-shrink: 0;
    height: utils.toRem(250px);

    @include breakpoints.query('>=md') {
      width: utils.toRem(200px);
    }
  }
}
