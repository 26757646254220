@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.rdp {
  --rdp-accent-color: colors.$secondary-color-900;
  --rdp-background-color: colors.$white;
  --rdp-accent-color-dark: colors.$secondary-color-900;
  --rdp-background-color-dark: colors.$secondary-color-900;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding-bottom: utils.toRem(16px);
  border: variables.$standard-border;
  box-shadow: 0px 4px 4px 0px #0000000d;

  @include breakpoints.query('>=md') {
    width: variables.$calendar-width;
  }
}

.rdp-button_reset,
.rdp-button,
.rpd-day {
  color: colors.$secondary-color-900;
}

// removes default styling (bolded) for "today's date"
.rdp-day_today:not(.rdp-day_outside) {
  font-weight: inherit;
}

.rdp-day_selected:not([disabled]) {
  color: colors.$white;
  background-color: colors.$secondary-color-900;
}

.rdp-day_selected:hover:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-01;
}

.rdp-day_selected:active:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-03;
}

.rdp-day_selected:focus:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-01;
  border: variables.$active-border;
}

.rdp-button_reset {
  color: colors.$secondary-color-900;
  background-color: none;
}

.rdp-button:active:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-03;
}

.rdp-button:hover:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-01;
}

.rdp-button:focus-visible:not([disabled]) {
  color: colors.$secondary-color-900;
  background-color: colors.$secondary-color-state-01;
  border: variables.$active-border;
}

// CAPTION (dropdown)
.rdp-caption_label {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  height: utils.toRem(40px);
  border: variables.$standard-border;
  padding: 0 utils.toRem(12px);
}

.rdp-caption_dropdowns:first-of-type {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: variables.$calendar-width;
  padding: utils.toRem(12px) 0;
  border-bottom: variables.$standard-border;
}

.rdp-dropdown_month:focus-within:not([disabled]),
.rdp-dropdown_year:focus-within:not([disabled]) {
  outline: variables.$active-border;
}

.rdp-dropdown_month,
.rdp-dropdown_year {
  @include typekit.font-subtitle-2-bold;
  color: colors.$secondary-color-900;
}

.rdp-dropdown_month {
  width: utils.toRem(144px);
  margin-right: utils.toRem(12px);
}

.rdp-dropdown_year {
  width: utils.toRem(104px);
}

// TABLE
.rdp-table {
  margin-left: auto;
  margin-right: auto;
}

.rdp-head_cell {
  @include typekit.font-caption;
  color: colors.$surface-color-inactive-med;
}

.rdp-cell {
  color: colors.$secondary-color-900;
}
