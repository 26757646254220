@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.text-field {
  position: relative;
  width: 100%;

  &__input {
    @include typekit.font-body-1;
    max-height: utils.toRem(56px);
    appearance: none;
    width: inherit;
    height: inherit;
    padding: utils.toRem(14px) utils.toRem(16px);
    border-radius: 0;
    border: 1px solid colors.$surface-color-inactive-low;
    background: colors.$white;
    outline: 4px solid transparent;
    transition: border-color 300ms ease-in-out, color 300ms ease-in-out;

    &:hover,
    &:active {
      border-color: colors.$surface-color-active;
      color: colors.$surface-color-active;
    }

    &:focus {
      border-color: colors.$surface-color-active;
      outline-color: colors.$secondary-color-400;
      color: colors.$surface-color-active;
      transition: none;
    }

    &--error,
    &--error:not(:placeholder-shown) {
      border-color: colors.$danger-color-700;
      color: colors.$danger-color-700;

      &:hover,
      &:focus,
      &:active {
        border-color: colors.$danger-color-700;
        color: colors.$danger-color-700;
      }
    }

    &:disabled {
      cursor: not-allowed;
      border-color: colors.$disabled-color-stroke;
      color: colors.$disabled-color;
    }
  }

  &__placeholder {
    position: absolute;
    left: utils.toRem(16px);
    cursor: text;
    top: utils.toRem(28px);
    transform: translateY(-50%);
    color: colors.$surface-color-inactive-med;
    transition: top 0.3s ease, 0.3s ease, color 0.3s ease;

    &--error {
      color: colors.$danger-color-700;
      top: utils.toRem(28px);
      transform: translateY(-50%);
    }

    &--tooltip {
      margin-right: utils.toRem(48px);
    }

    &--disabled {
      color: colors.$disabled-color;
    }
  }

  &__input:focus + &__placeholder,
  &__input:focus:not(:placeholder-shown) + &__placeholder {
    @include typekit.font-caption;
    top: 0;
    background: colors.$white;
    color: colors.$surface-color-active;
    padding: 0 3px;
  }

  &__input:not(:placeholder-shown) + &__placeholder {
    @include typekit.font-caption;
    top: 0;
    background: colors.$white;
    color: colors.$surface-color-inactive-med;
    padding: 0 3px;
  }

  &__input--error:focus:not(:placeholder-shown) + &__placeholder--error,
  &__input--error:not(:placeholder-shown) + &__placeholder--error,
  &__input--error:focus + &__placeholder--error {
    @include typekit.font-caption;
    top: 0;
    background: colors.$white;
    color: colors.$danger-color-700;
    padding: 0 3px;
  }

  &__icon-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    margin-top: utils.toRem(16px);
    margin-right: utils.toRem(16px);
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    z-index: variables.$z-index-tooltip-wrapper;
  }

  &__icon {
    width: inherit;
    height: inherit;
    fill: colors.$surface-color-inactive-med;

    &--disabled {
      fill: colors.$disabled-color;
    }
  }

  &__icon-button {
    &:focus {
      outline: colors.$secondary-color-400 solid utils.toRem(4px);
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__tooltip {
    width: inherit;
    height: inherit;
  }

  &__bottom-row {
    padding: utils.toRem(1px) utils.toRem(16px);
    display: flex;
    justify-content: flex-start;
    width: 100%;
    @include typekit.font-caption;
    color: colors.$surface-color-inactive-med;

    &--error {
      color: colors.$danger-color-700;
    }

    &__helper-text {
      max-width: utils.toRem(315px);
      max-height: utils.toRem(32px);
    }
  }
}
