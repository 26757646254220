@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.custom-date-range-filter {
  display: flex;
  flex-direction: column;
  padding: utils.toRem(16px);
  cursor: auto;

  &__header-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: utils.toRem(10px);
  }

  &__back {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }
  }

  &__back-button {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    fill: colors.$surface-color-active;
    cursor: pointer;
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-left: utils.toRem(10px);
  }

  &__subtitle {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);
  }

  &__calendar-section-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: utils.toRem(16px);
  }

  &__calendar-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: utils.toRem(14px) utils.toRem(16px);
    background-color: colors.$surface-color-variant;
    border: 1px solid transparent;

    &--error {
      border-color: colors.$danger-color-700;
    }
  }

  &__date {
    @include typekit.font-subtitle-1;
    appearance: none;
    width: inherit;
    height: inherit;
    outline: none;
    border: none;
    color: colors.$surface-color-active;
    background-color: colors.$surface-color-variant;

    &--error {
      color: colors.$danger-color-700;
    }
  }

  &__calendar-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-right: utils.toRem(8px);
    fill: colors.$secondary-color-700;

    &--error {
      fill: colors.$danger-color-700;
    }
  }

  &__dash {
    @include typekit.font-subtitle-1;
    display: flex;
    height: utils.toRem(52px);
    align-items: center;
    justify-content: center;
    margin: 0 utils.toRem(16px);
  }

  &__divider {
    width: 100%;
    color: colors.$surface-color-stroke;
    size: 1px;
    margin-bottom: utils.toRem(16px);
  }

  &__cta-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &__error {
    @include typekit.font-caption;
    margin-top: utils.toRem(16px);
    color: colors.$danger-color-700;
  }
}
