@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.product-selection {
  &__accordion-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);
  }

  &__content {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }
  }

  &__steps-wrapper {
    background-color: colors.$surface-color-variant;
    padding: utils.toRem(16px) utils.toRem(16px) 0;

    @include breakpoints.query('>=md') {
      padding: 0;
    }
  }

  &__steps-form {
    width: 100%;
    max-width: utils.toRem(1134px);
  }

  &__subtitle-wrapper {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: utils.toRem(16px);
  }

  &__subtitle {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__loading {
    height: utils.toRem(264px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.group-accordion {
  color: colors.$surface-color-active;
  width: 100%;

  @include breakpoints.query('>=md') {
    max-width: utils.toRem(672px);
  }

  & &__remove-product {
    width: fit-content;
    border: none;
    padding: utils.toRem(8px);
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      border: none;
      border-radius: 100%;
      height: utils.toRem(49px);
      width: utils.toRem(49px);
      margin-bottom: 0;
      flex-shrink: 0;

      & svg {
        margin: 0;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    flex-direction: row;
    gap: utils.toRem(16px);
    justify-content: center;
    align-items: center;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title {
    @include typekit.font-display-6;
  }

  &__subtitle {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
  }

  & &__button {
    background-color: colors.$white;

    & svg {
      margin: 0;
    }
  }

  &__container {
    border: none;
    background-color: colors.$white;
  }

  &__inner-container {
    line-height: normal;
  }

  &__form {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      gap: utils.toRem(16px);
    }
  }

  &__input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }
  }

  &__acre-input {
    @include breakpoints.query('>=md') {
      flex: 40%;
    }
  }

  &__product-input {
    @include breakpoints.query('>=md') {
      flex: 60%;
    }
  }
}