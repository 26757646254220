@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/color-enums';
@use 'src/assets/styles/base/hero';

.validation-criteria {
  display: inline-flex;
  width: 100%;

  &__icon-wrapper {
    width: utils.toRem(24px);
    margin-right: utils.toRem(16px);
    display: flex;
    justify-content: center;
    flex-shrink: 0;
  }

  &__icon {
    @include color-enums.validationStateFill;
    width: 100%;
    height: 100%;
  }

  &__label {
    @include typekit.font-subtitle-2;
    @include color-enums.validationStateColors;
  }
}
