@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.custom-caption {
  &__button {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }

    &--left {
      margin-right: utils.toRem(5px);
    }

    &--right {
      margin-left: utils.toRem(5px);
    }
  }
}

.arrow-icon {
  fill: colors.$surface-color-inactive-med;
  width: utils.toRem(24px);
  height: utils.toRem(24px);
}
