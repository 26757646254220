@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.tooltip {
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  opacity: 0;
  width: utils.toRem(200px);
  padding: utils.toRem(16px);
  display: none;
  border:  variables.$standard-border;
  z-index: variables.$z-index-tooltip;

  &--light {
    background-color: colors.$white;
    color: colors.$secondary-color-900;
  }

  &--dark {
    background-color: colors.$secondary-color-900;
    color: colors.$white;
  }

  @include breakpoints.query('>=md') {
    width: utils.toRem(250px);
  }

  &--visible {
    opacity: 1;
    position: absolute;
    display: inline-flex;
  }

  &--visible.top {
    bottom: 100%;
    left: 50%;
    transform: translate(-50%, utils.toRem(-20px));
  }

  &--visible.right {
    top: -50%;
    left: 100%;
    transform: translate(utils.toRem(20px), 0);
  }

  &--visible.bottom {
    top: 100%;
    left: 50%;
    transform: translate(-50%, utils.toRem(20px));
  }

  &--visible.left {
    top: -50%;
    right: 100%;
    transform: translate(utils.toRem(-20px), 0);
  }

  &__text {
    display: flex;
    color: currentColor;
    @include typekit.font-caption;
  }

  &__heading {
    @include typekit.font-subtitle-2;
    color: currentColor;
    margin-bottom: utils.toRem(4px);
  }

  &__wrapper {
    display: inline-flex;
    position: relative;
    will-change: filter;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
    z-index: variables.$z-index-tooltip-wrapper;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &--visible {
      &:before,
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: utils.toRem(11px);
        top: 50%;
        z-index: variables.$z-index-tooltip;
      }

      &:before {
        border-width: utils.toRem(12px);
        border-color: colors.$surface-color-stroke transparent transparent transparent;
      }
    }

    &--light {
      &:after {
        border-color: colors.$white transparent transparent transparent;
      }
    }

    &--dark {
      &:after {
        border-color: colors.$secondary-color-900 transparent transparent transparent;
      }
    }

    &.top {
      &:before,
      &:after {
        top: utils.toRem(-21px);
      }

      &:after {
        left: calc(50% - #{utils.toRem(10px)});
      }

      &:before {
        left: calc(50% - #{utils.toRem(11px)});
      }
    }

    &.right {
      &:before,
      &:after {
        transform: translate(0, -50%) rotate(90deg);
      }

      &:after {
        left: calc(100% + #{utils.toRem(1px)});
      }

      &:before {
        left: calc(100% + #{utils.toRem(-1px)});
      }
    }

    &.bottom {
      &:before,
      &:after {
        transform: rotate(180deg);
      }

      &:after {
        top: calc(100% + #{utils.toRem(-1px)});
        left: calc(50% - #{utils.toRem(11px)});
      }

      &:before {
        top: calc(100% + #{utils.toRem(-3px)});
        left: calc(50% - #{utils.toRem(12px)});
      }
    }

    &.left {
      &:before,
      &:after {
        transform: translate(0, -50%) rotate(270deg);
      }

      &:after {
        right: calc(100% + #{utils.toRem(1px)});
      }

      &:before {
        right: calc(100% + #{utils.toRem(-1px)});
      }
    }
  }
}
