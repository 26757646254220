@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.snackbar-container {
  position: fixed;
  bottom: utils.toRem(16px);
  right: utils.toRem(16px);
  z-index: variables.$z-index-snackbar;
  display: flex;
  flex-direction: column;
}

.snack {
  margin-bottom: utils.toRem(8px);
}
