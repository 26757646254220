@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/animations';

.phone-verification {
  &__description {
    @include typekit.font-body-2;
    display: inline-block;
    color: colors.$surface-color-inactive-med;
  }

  &__list {
    @include typekit.font-body-2;
    list-style: inside;
    color: colors.$surface-color-inactive-med;
    margin: utils.toRem(24px) 0;
  }

  &__resend {
    color: colors.$secondary-color-900;
  }

  &__button {
    width: 100%;

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__form {
    margin-top: utils.toRem(24px);
  }

  &__phone {
    @include typekit.font-body-2-bold;
    margin: 0 utils.toRem(8px);
  }
}
