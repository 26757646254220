@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/animations';

.complete-account {
  &__form {
    margin-top: utils.toRem(20px);
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
  }

  &__button {
    width: 100%;

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__validation-criteria {
    margin: utils.toRem(16px) 0;
  }
}

.consent-form {
  width: 100%;
  overflow-y: scroll;
  background-color: colors.$surface-color-variant;
  padding: utils.toRem(16px) utils.toRem(16px) 0 utils.toRem(16px);
  white-space: pre-line;
  margin-bottom: utils.toRem(16px);

  &::-webkit-scrollbar-thumb {
    background: colors.$surface-color-stroke;
  }

  &--md {
    height: utils.toRem(130px);
  }

  &--lg {
    height: utils.toRem(300px);
  }

  @include breakpoints.query('>=md') {
    &--lg {
      height: utils.toRem(200px);
    }
  }

  &__header {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(24px);
  }

  &__sub-header {
    display: block;
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(24px);

    &:nth-of-type(2) {
      margin-top: utils.toRem(24px);
      margin-bottom: utils.toRem(8px);
    }
  }

  &__list {
    list-style: disc;
    padding-left: utils.toRem(16px);

    &--rule {
      @include typekit.font-subtitle-2;
      color: colors.$surface-color-active;
      padding: 0;
    }
  }

  &__checkbox {
    margin-bottom: utils.toRem(16px);
  }

  &__line {
    color: rgba($color: colors.$secondary-color-900, $alpha: 0.12);
    height: 1px;
    margin: utils.toRem(24px) 0;
  }

  &__continue {
    margin-top: utils.toRem(24px);
    width: 100%;
  }

  &__summary {
    display: block;
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(20px);
  }
}
