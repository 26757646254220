@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.settings-navigation {
  @include breakpoints.query('>=lg') {
    width: utils.toRem(293px);
  }

  &__link {
    @include typekit.font-subtitle-1-bold;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: utils.toRem(16px) utils.toRem(24px) utils.toRem(16px) utils.toRem(20px);
    border-left: variables.$inactive-border;
    color: colors.$surface-color-active;
    background-color: transparent;
    transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out;

    &--active {
      background-color: colors.$secondary-color-state-01;
      border-color: colors.$secondary-color-400;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: utils.toRem(-4px);
      border: variables.$inactive-border;
      transition: border-color 300ms ease-in-out;
    }

    &:hover {
      background-color: colors.$secondary-color-state-01;
    }

    &:focus {
      outline: none;

      &::before {
        border-color: colors.$secondary-color-400;
      }
    }
  }

  &__icon-text {
    display: flex;
    align-items: center;
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-right: utils.toRem(16px);
    fill: currentColor;
  }

  &__chevron {
    flex: 0 0 auto;
    opacity: 0;
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: currentColor;
    transition: opacity 300ms ease-in-out;

    &--active {
      opacity: 1;
    }

    &--open {
      transform: rotate(180deg);
    }
  }

  &__link--active &__chevron {
    opacity: 1;
  }

  &__toggle {
    @include layout.container-md;
    @include typekit.font-subtitle-1-bold;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: colors.$surface-color-active;
    padding-top: utils.toRem(16px);
    padding-bottom: utils.toRem(16px);
    border-bottom: variables.$standard-border;
    cursor: pointer;

    @include breakpoints.query('>=lg') {
      display: none;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: variables.$inactive-border;
      transition: border-color 300ms ease-in-out;
    }

    &:hover {
      background-color: colors.$secondary-color-state-01;
    }

    &:focus {
      outline: none;

      &::before {
        border-color: colors.$secondary-color-400;
      }
    }
  }

  &__nav {
    position: fixed;
    top: calc(#{variables.$header-height-mobile} + #{utils.toRem(56px)});
    right: 0;
    bottom: 0;
    left: 0;
    z-index: variables.$z-index-secondary-nav;
    background-color: colors.$white;

    &--hidden {
      display: none;
    }

    @include breakpoints.query('>=lg') {
      position: static;
      top: unset;
      right: unset;
      bottom: unset;
      left: unset;
      z-index: initial;
    }
  }
}
