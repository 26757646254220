@use 'sass:math';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.info-banner-section {
  @include layout.container-md;

  &--no-banner {
    padding-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      padding-bottom: utils.toRem(48px);
    }
  }

  &__wrapper {
    padding: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(48px) 0;
    }
  }
}

.no-rewards {
  padding: utils.toRem(16px);
  background-color: colors.$surface-color-variant;
  color: colors.$surface-color-active;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(24px);
  }

  &__title {
    @include typekit.font-overline;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      text-align: center;
    }
  }

  &__description {
    @include typekit.font-body-2;
    display: flex;
    align-items: center;

    @include breakpoints.query('>=md') {
      justify-content: center;
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(16px);
    height: utils.toRem(16px);
    margin-right: utils.toRem(4px);
    fill: currentColor;
  }
}
