@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

@mixin default {
  width: 100%;

  @include breakpoints.query('>=md') {
    border-collapse: collapse;
    margin-top: utils.toRem(32px);
    color: colors.$surface-color-active;
    border: variables.$standard-border;
  }

  &__header {
    @include typekit.font-overline;
    padding: utils.toRem(16px);
    color: colors.$surface-color-inactive-med;
    text-align: left;

    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &:first-of-type {
      padding-left: utils.toRem(32px);
    }

    &:last-of-type {
      padding-right: utils.toRem(32px);
    }
  }

  &__tbody {
    width: inherit;
    display: flex;
    flex-direction: column;

    & .table__data-row:nth-child(odd) {
      background-color: colors.$surface-color-variant;
    }

    @include breakpoints.query('>=md') {
      display: revert;
      flex-direction: revert;
    }
  }

  &__data-row {
    padding: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      border: variables.$standard-border;
    }
  }

  &__data-cell {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
    padding-block: utils.toRem(4px);
    display: inline-block;
    text-align: right;
    width: 100%;

    @include breakpoints.query('>=md') {
      width: revert;
      display: table-cell;
      padding: utils.toRem(16px);
      text-align: revert;

      &--right {
        text-align: right;
      }

      &--center {
        text-align: center;
      }

      &:first-of-type {
        padding-left: utils.toRem(32px);
      }

      &:last-of-type {
        padding-right: utils.toRem(32px);
      }
    }
  }

  &__data-cell::before {
    @include typekit.font-body-2-bold;
    content: attr(data-label);
    position: absolute;
    left: utils.toRem(34px);
    text-align: left;
    white-space: pre;

    @include breakpoints.query('>=md') {
      content: revert;
      position: revert;
      left: 0;
    }
  }

  &__empty-state {
    background-color: colors.$white;

    @include breakpoints.query('>=md') {
      border-top: variables.$standard-border;
    }
  }
}
