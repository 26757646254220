@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.modal-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: variables.$z-index-modal;
  outline: 0;
  width: 100%;
  box-shadow: 0 utils.toRem(24px) utils.toRem(38px) 3px rgba($color: colors.$black, $alpha: 0.14),
    0 utils.toRem(9px) utils.toRem(46px) utils.toRem(8px) rgba($color: colors.$black, $alpha: 0.12),
    0 utils.toRem(11px) utils.toRem(15px) utils.toRem(-7px) rgba($color: colors.$black, $alpha: 0.2);
  background-color: colors.$white;
  padding: 0 utils.toRem(16px);
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: column;

  @include breakpoints.query('>=md') {
    padding: 0 utils.toRem(24px);
    width: utils.toRem(560px);
    max-height: utils.toRem(761px);
    border-radius: 4px;
    height: auto;
  }
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: colors.$black, $alpha: 0.3);
  z-index: 500;
}

.modal-header {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: variables.$z-index-modal-header;
  padding: utils.toRem(16px) 0;
  background-color: colors.$white;
  text-transform: capitalize;
}

.modal-title {
  @include typekit.font-display-6;
  color: colors.$secondary-color-900;
  padding: utils.toRem(8px) 0;
  text-align: center;
  flex: 1;

  &--fr {
    text-transform: none;
  }
}

.modal-content {
  z-index: variables.$z-index-modal-content;
  position: relative;
  overflow-y: scroll;
  flex: 1;
}

.modal-content-header {
  z-index: variables.$z-index-modal-header;
  position: sticky;
  top: 0;
}

.modal-footer {
  position: sticky;
  bottom: 0;
  z-index: variables.$z-index-modal-footer;
  background-color: colors.$white;

  &__wrapper {
    padding: utils.toRem(16px) 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column-reverse;

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }

    &--with-border {
      border-top: 1px solid colors.$surface-color-stroke;
      margin: 0 utils.toRem(-24px);
      padding: utils.toRem(16px) utils.toRem(24px);
    }
  }

  &__text-link {
    margin-top: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      margin-top: 0;
      margin-right: utils.toRem(8px);
    }
  }

  &:before {
    content: '';
    display: block;
    width: calc(100% + #{utils.toRem(32px)});
    position: absolute;
    top: 0;
    left: utils.toRem(-16px);
    height: 1px;
    background-color: colors.$surface-color-disabled;

    @include breakpoints.query('>=md') {
      display: none;
    }
  }
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  width: utils.toRem(48px);
  height: utils.toRem(48px);
  padding: utils.toRem(12px);

  &:focus {
    outline: variables.$active-border;
  }
}
