@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/layout';

.rewards-year-picker {
  display: flex;
  align-items: center;
  gap: utils.toRem(8px);
}

.sticky-picker {
  position: sticky;
  top: variables.$header-height-mobile;
  background-color: colors.$white;
  border-bottom: variables.$standard-border;
  z-index: variables.$z-index-secondary-nav;

  @include breakpoints.query('>=xl') {
    top: variables.$header-height-desktop;
  }

  &__container {
    @include layout.container-md;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: utils.toRem(16px);
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
  }
}
