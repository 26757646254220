@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';

.pre-auth-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    @include layout.center-in-window;
  }
}
