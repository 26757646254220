@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/form-layout';
@use 'src/assets/styles/base/typekit';

.pre-order-details-modal {
  &__form-inner {
    display: grid;
    grid-template-columns: max-content minmax(50%, #{utils.toRem(348px)});
    align-items: center;
    justify-content: space-between;
    margin-block: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      grid-template-columns: 1fr minmax(50%, #{utils.toRem(348px)});
    }
  }

  &__field-label {
    @include typekit.font-body-1;
    margin-right: utils.toRem(16px);
    color: colors.$surface-color-active;
  }

  &__field-input {
    width: 100%;
  }

  &__list-item {
    margin: utils.toRem(16px) 0;
  }

  &__label {
    cursor: pointer;
  }

  &__modal-wrapper {
    @include breakpoints.query('>=md') {
      overflow-y: auto;
    }
  }

  &__modal-content {
    overflow: unset;

    // We are overflowing the whole modal for this component,
    // temporarly setting modal content to be below modal-footer zIndex which is 8
    // so that footer will overlap modal content
    z-index: variables.$z-index-modal-footer - 1;
  }

  &__subheading {
    @include typekit.font-body-1-bold;
    color: colors.$surface-color-active;

    &--overview {
      display: flex;
      align-items: center;
    }
  }

  
  &__hr {
    display: none;
    height: 1px;
    border: none;
    margin-top: 1px;
    margin-bottom: utils.toRem(16px);
    background-color: colors.$surface-color-stroke;

    @include breakpoints.query('>=md') {
      display: block;
    }

    &--show-mobile {
      display: block;
    }

    &--no-margin {
      margin: 0;
    }
  }

  &__checkmark {
    fill: colors.$success-green;
    width: utils.toRem(24px);
    margin-right: utils.toRem(13px);
  }

  &__order-container-wrapper {
    margin-top: utils.toRem(16px);
  }

  &__button-icon {
    @include animations.spinner-rotate-left-animation;
  }

  &__text {
    @include typekit.font-body-1;
    margin-bottom: utils.toRem(16px);
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-block: utils.toRem(8px);
  }

  &__selected-retailer {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }
}

.helper-text {
  @include typekit.font-caption;
  color: colors.$surface-color-inactive-med;

  &--errors {
    color: colors.$danger-color-700;
  }
}

.acreage {
  display: flex;
  align-items: start;
  padding: utils.toRem(16px);
  background-color: colors.$surface-color-variant;
  margin-bottom: utils.toRem(24px);

  &__icon-wrapper {
    margin-right: utils.toRem(8px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    margin-bottom: utils.toRem(10px);
    color: colors.$surface-color-active;
  }

  &__description {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }
}
