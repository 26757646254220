@use 'sass:math';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  display: flex;
  flex-direction: column;
  border: variables.$standard-border;

  &__product-img {
    width: utils.toRem(102px);
    height: utils.toRem(102px);
    flex-basis: utils.toRem(102px);
    background-position: center;
    background-size: cover;
    background-color: colors.$surface-color-variant;
    flex-shrink: 0;
  }

  &__upper-section,
  &__lower-section {
    display: flex;
    justify-content: space-between;
  }

  &__lower-section {
    border-top: variables.$standard-border;
    padding: utils.toRem(16px);
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    overflow: hidden;
    padding: utils.toRem(16px);
    box-sizing: border-box;
  }

  &__text {
    height: utils.toRem(16px);
    background-color: colors.$surface-color-stroke;

    &--small {
      width: math.percentage(math.div(116px, 416px));
    }

    &--medium {
      width: math.percentage(math.div(166px, 416px));
    }

    &--large {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(8px);
    }
  }
}
