@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/table';

.bayer-value {
  width: 100%;

  &__divider {
    @include layout.page-section-divider;
  }
}

.segments {
  @include layout.container-md;

  &__wrapper {
    padding: utils.toRem(32px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(48px) 0;
    }
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
    display: block;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
    }
  }

  &__header-skeleton {
    width: 60%;
    height: utils.toRem(40px);
    background-color: colors.$skeleton-mask;
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      width: 30%;
    }
  }

  &__empty-state {
    margin-top: utils.toRem(32px);
    border: variables.$standard-border;
  }
}

.table {
  @include table.default;
}

.spinner-wrapper {
  border: variables.$standard-border;
  padding: utils.toRem(32px) 0;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(78px) 0;
  }
}
