@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.tabs {
  &__list {
    @include layout.hide-scrollbar;
    display: flex;
    align-items: center;
    overflow-x: auto;
  }

  &__list-item {
    flex-shrink: 0;
  }

  &__link {
    @include typekit.font-button;
    position: relative;
    display: inline-block;
    padding: utils.toRem(16px) utils.toRem(35px);
    color: colors.$surface-color-inactive-med;
    background-color: transparent;
    border-bottom: 2px solid colors.$disabled-color-stroke;
    z-index: 0;
    transition: background-color 300ms ease-in-out, color 300ms ease-in-out,
      border-color 300ms ease-in-out;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: -2px; // Width of the border bottom on the anchor
      left: 0;
      border: 4px solid transparent;
      z-index: 1;
      transition: border-color 300ms ease-in-out;
    }

    &:hover {
      background-color: colors.$surface-color-variant;
    }

    &:focus {
      background-color: colors.$surface-color-variant;
      border-color: colors.$secondary-color-700;
      outline: none;

      &::before {
        border-color: colors.$secondary-color-400 colors.$secondary-color-400 transparent;
      }
    }

    &:active {
      background-color: colors.$skeleton-mask;
      border-color: colors.$disabled-color-stroke;

      &::before {
        border-color: transparent;
      }
    }

    &--active {
      color: colors.$primary-color-400;
      border-color: colors.$primary-color-400;

      &:hover {
        background-color: colors.$primary-color-state-01;
      }

      &:focus {
        background-color: colors.$primary-color-state-01;
        border-color: colors.$primary-color-state-01;

        &::before {
          border-color: colors.$secondary-color-400;
        }
      }

      &:active {
        background-color: colors.$primary-color-state-02;
        border-color: colors.$primary-color-400;

        &::before {
          border-color: transparent;
        }
      }
    }

    &--disabled {
      pointer-events: none;
      color: colors.$disabled-color;
      border-color: colors.$disabled-color;
    }
  }

  &__panel {
    &--hidden {
      display: none;
    }
  }
}
