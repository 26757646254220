@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.breakdown-section {
  @include layout.container-md;
  margin-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(60px);
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
    text-align: center;
  }

  &__skeleton-heading {
    height: utils.toRem(24px);
    width: 100%;
    max-width: utils.toRem(300px);
    margin-inline: auto;
    margin-bottom: utils.toRem(16px);
    background-color: colors.$skeleton-mask;
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    text-align: center;

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(577px);
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__skeleton-description {
    height: utils.toRem(40px);
    width: 100%;
    background-color: colors.$skeleton-mask;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(577px);
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include breakpoints.query('>=md') {
      padding-top: utils.toRem(52px);
      flex-direction: row;
      align-items: center;
    }
  }

  &__chart-container {
    display: flex;
    justify-content: center;
    padding: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      padding: 0;
      flex-grow: 1;
    }
  }

  &__chart-wrapper {
    max-width: utils.toRem(240px);

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(300px);
    }
  }

  &__cards-wrapper {
    @include breakpoints.query('>=md') {
      width: math.percentage(math.div(546px, 1120px));
    }
  }

  &__card {
    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);

      @include breakpoints.query('>=md') {
        margin-bottom: utils.toRem(24px);
      }
    }
  }

  &__spinner-wrapper {
    padding-top: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      padding-top: utils.toRem(136px);
      padding-bottom: utils.toRem(86px);
    }
  }

  &__enrolled {
    padding: 0 utils.toRem(16px);
  }

  &__enrolled-wrapper {
    padding-block: utils.toRem(32px) utils.toRem(16px);
  }

  &__enrolled-card {
    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__enrolled-footer {
    display: flex;
    align-items: center;
    padding: utils.toRem(16px) 0;
    border-top: variables.$standard-border;
  }

  &__enrolled-icon {
    fill: colors.$surface-color-inactive-med;
    width: utils.toRem(16px);
    height: utils.toRem(16px);
    margin-right: utils.toRem(8px);
  }

  &__enrolled-footer-text {
    color: colors.$surface-color-inactive-med;
  }
}
