@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/utils';

.empty-state-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: utils.toRem(32px);

  &__icon {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    color: colors.$white;
  }

  &__icon-wrapper {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: utils.toRem(24px);

    &--primary {
      background-color: colors.$primary-color-400;
    }

    &--secondary {
      background-color: colors.$surface-color-active;
    }
  }

  &__title {
    @include typekit.font-subtitle-1-bold;
  }

  &__subtitle {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
    white-space: pre-line;
  }
}
