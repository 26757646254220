@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/abstracts/utils';

.splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    padding-block: utils.toRem(64px) utils.toRem(130px);
  }

  &__button {
    max-width: utils.toRem(432px);
    width: 100%;
  }

  &__video {
    width: 100%;
    max-width: utils.toRem(640px);
    max-height: utils.toRem(480px);
  }

  &__title {
    @include typekit.font-display-5;
    text-align: center;
    color: colors.$surface-color-active;
    padding: utils.toRem(16px) 0;
  }

  &__subtitle {
    @include typekit.font-body-2;
    text-align: center;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);
    max-width: utils.toRem(405px);
  }
}
