@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.user-permissions {
  width: 100%;
  margin-top: utils.toRem(32px);
  margin-bottom: utils.toRem(24px);

  &__top-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: utils.toRem(32px);
    }
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__icon-wrapper {
    display: inline-flex;
  }

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__sub-heading {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    width: 100%;

    &--modal {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__subtitle {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
  }

  &__divider {
    width: 100%;
    border-top: variables.$standard-border;
    margin: utils.toRem(24px) 0;

    @include breakpoints.query('>=md') {
      margin: utils.toRem(32px) 0;
    }
  }

  &__button {
    margin-top: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin-top: utils.toRem(10px);
    }
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
    margin-right: utils.toRem(16px);

    &--transfer {
      fill: colors.$danger-color-700;
    }
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }
}

.form {
  margin-top: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    margin-block: utils.toRem(32px) utils.toRem(24px);
  }
}

.modal {
  @include breakpoints.query('>=md') {
    overflow-y: visible;
  }
}

.form-dropdown {
  margin: 0 utils.toRem(5px) utils.toRem(24px) utils.toRem(5px);

  &__partner {
    @include breakpoints.query('>=md') {
      width: utils.toRem(288px);
    }
  }

  &__avatar {
    width: utils.toRem(32px);
    height: utils.toRem(32px);
  }
}

.options-footer {
  width: 100%;
  height: utils.toRem(56px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  cursor: pointer;

  &__separator {
    border-top: 1px solid colors.$surface-color-inactive-low;
    flex: 0 0 auto;
  }

  &__content {
    padding: 0 utils.toRem(16px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex: 1 1 auto;
  }

  &__label {
    @include typekit.font-subtitle-1-bold;
    display: block;
    color: colors.$danger-color-700;
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$danger-color-700;
  }

  &__modal {
    &--subtitle {
      @include typekit.font-subtitle-1-bold;
      color: colors.$surface-color-active;
      margin-bottom: utils.toRem(8px);
    }
  }
}

.user {
  width: 100%;
  padding: utils.toRem(16px);
  border: variables.$standard-border;
  display: flex;
  flex-direction: column;

  @include breakpoints.query('>=md') {
    flex-direction: row;
    align-items: center;
  }

  &:not(:last-of-type) {
    margin-bottom: utils.toRem(16px);
  }

  &__image {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    border-radius: utils.toRem(100px);
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-top: utils.toRem(16px);
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin: 0 utils.toRem(16px);
      max-width: utils.toRem(336px);
    }
  }

  &__text-wrapper--no-avatar {
    margin-top: 0;

    @include breakpoints.query('>=md') {
      margin-left: 0;
    }
  }

  &__action-wrapper {
    @include breakpoints.query('>=md') {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__role {
    @include typekit.font-overline;
    color: colors.$surface-color-active;
  }

  &__name {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;

    &--farm {
      display: none;

      @include breakpoints.query('>=md') {
        display: block;
      }
    }
  }

  &__responsibilities {
    @include typekit.font-subtitle-1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: colors.$surface-color-inactive-med;

    &--open {
      white-space: wrap;
    }
  }

  &__email {
    @include typekit.font-body-1;
    color: colors.$surface-color-active;
  }

  &__invited {
    @include typekit.font-body-1;
    color: colors.$success-green;
    display: flex;
    gap: 4px;
  }

  &__invited-icon {
    width: utils.toRem(24px);
    fill: colors.$success-green;
  }

  &__farm {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
    }
  }

  &__address {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__view-button {
    color: colors.$secondary-color-700;
  }

  &__invite-button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
      margin-top: 0;
    }
  }
}
