@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.linear-chart-tooltip {
  background-color: colors.$white;
  min-width: utils.toRem(228px);
  padding: utils.toRem(16px);
  border: variables.$standard-border;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &__label {
    @include typekit.font-caption-bold;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(12px);
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(12px);
  }

  &__wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: utils.toRem(8px);
  }

  &__inner-wrapper {
    display: inline-flex;
    align-items: center;
    gap: utils.toRem(8px);
  }

  &__icon-wrapper {
    width: utils.toRem(14px);
    height: utils.toRem(14px);
    border-radius: utils.toRem(36px);
    border: utils.toRem(3px) solid colors.$white;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.12))
      drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14));
  }

  &__icon {
    width: inherit;
    height: inherit;
  }

  &__key {
    @include typekit.font-caption;
    color: colors.$secondary-color-900;
  }

  &__value {
    @include typekit.font-caption-bold;
    color: colors.$secondary-color-900;
  }
}
