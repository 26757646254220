@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.download-list-modal {
  &__description {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-inactive-med;
  }

  &__spinner {
    @include animations.spinner-rotate-left-animation;
  }
}
