@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.success-cta {
  @include breakpoints.query('>=md') {
    flex-direction: row;
    justify-content: flex-end;
  }

  @include breakpoints.query('>xl') {
    justify-content: center;
  }

  &__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: utils.toRem(32px);
  }

  &__inner {
    width: 100%;
    max-width: calc(utils.toRem(432px) + utils.toRem(64px));
  }

  &__header {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
    text-align: left;
  }

  &__details {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
    text-align: left;
    margin-bottom: utils.toRem(24px);
  }
}
