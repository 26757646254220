@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.checkbox {
  width: fit-content;
  height: fit-content;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  cursor: pointer;

  &--with-image {
    width: 100%;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    border: variables.$standard-border;
    padding-inline: utils.toRem(16px);
  }

  &__label {
    @include typekit.font-body-2;
    color: colors.$surface-color-active;
    margin-left: utils.toRem(8px);
    padding-top: utils.toRem(10px);

    &--with-image {
      display: flex;
      align-items: center;
      gap: utils.toRem(16px);
      margin: 0;
      padding: utils.toRem(12px) 0;
    }
  }

  &__input,
  &__input:checked {
    appearance: none;
    opacity: 0;
  }

  &__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    height: utils.toRem(40px);
    width: utils.toRem(40px);
    border: variables.$inactive-border;
    border-radius: utils.toRem(20px);
    background-color: transparent;
    transition: border-color 300ms ease-in-out, background-color 300ms ease-in-out;

    &:hover {
      background-color: colors.$primary-color-state-01;
    }

    &:focus-within {
      background-color: colors.$primary-color-state-02;
      border-color: colors.$secondary-color-400;
    }

    &:active {
      background-color: colors.$primary-color-state-03;
    }
  }

  &__box {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    padding: 3px;
    position: relative;
    border: 2px solid colors.$surface-color-inactive-med;

    &--disabled {
      border: 2px solid colors.$surface-color-inactive-low;
      pointer-events: none;
    }

    &--error {
      border: 2px solid colors.$danger-color-700;
    }
  }

  &__check,
  &__indeterminate {
    opacity: 0;
    position: absolute;
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    fill: colors.$white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: colors.$primary-color-400;

    &:hover {
      fill: colors.$primary-color-state-01;
    }

    &:focus-within {
      fill: colors.$primary-color-state-02;
    }

    &:active {
      fill: colors.$primary-color-state-03;
    }

    &--disabled {
      pointer-events: none;
      background-color: colors.$surface-color-inactive-low;
    }

    &--error {
      background-color: colors.$danger-color-700;
    }
  }

  &__input:checked + &__box {
    border-color: transparent;
  }

  &__input:checked + &__box &__check {
    opacity: 1;
  }

  &__input:checked + &__box &__indeterminate {
    opacity: 1;
  }
}
