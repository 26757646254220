@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/variables';

.app {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;

  &__header,
  &__footer,
  &__main {
    flex-shrink: 0;
  }

  &__main {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    padding-top: variables.$header-height-mobile;

    @include breakpoints.query('>=xl') {
      padding-top: variables.$header-height-desktop;
    }
  }
}
