@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/table';
@use 'sass:math';

.table {
  @include table.default;

  @include breakpoints.query('>=md') {
    margin-top: 0;
  }

  &__header,
  &__data-cell {
    &:first-of-type {
      padding-left: utils.toRem(16px);
    }

    &:last-of-type {
      padding-right: utils.toRem(16px);
    }

    @include breakpoints.query('>=md') {
      padding: utils.toRem(12px);
    }
  }

  &__icon-wrapper {
    display: flex;
    gap: utils.toRem(8px);
  }

  &__details-row {
    max-width: utils.toRem(100px);
    word-wrap: break-word;
  }
}

.breakdown-section {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(16px);
  margin-top: utils.toRem(16px);

  &__header {
    @include typekit.font-overline;
    display: block;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(8px);
  }

  &__program-list {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);
  }

  &__program-wrapper {
    display: flex;
    gap: utils.toRem(8px);
  }
}

.mobile-table {
  &--accordion {
    border-collapse: collapse;
  }

  &__accordion-header {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);
  }

  &__middle-dot {
    margin: 0 utils.toRem(8px);
  }

  &__row {
    margin: 0;
    padding: utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);

    &--striped {
      &:nth-child(odd) {
        background-color: colors.$surface-color-variant;
      }
    }
  }

  &__column {
    margin: 0;
    display: flex;
    gap: utils.toRem(8px);
    color: colors.$surface-color-active;
  }

  &__header {
    @include typekit.font-subtitle-2-bold;
    margin: 0;
  }

  &__value,
  &__label {
    @include typekit.font-body-2;
    margin-left: 0;
  }
}

.spinner-wrapper {
  border: variables.$standard-border;
  padding: utils.toRem(32px) 0;
}

.cheques-section {
  @include layout.container-md;

  &__wrapper {
    padding-block: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      padding-block: utils.toRem(48px);
    }
  }

  &__header {
    margin-bottom: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(40px);
    }
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
    display: block;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
    }
  }

  &__sub-title {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    max-width: utils.toRem(577px);
    display: block;
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      margin-bottom: 0;
    }
  }

  &__divider {
    @include layout.page-section-divider;
    width: 100%;

    @include breakpoints.query('>=md') {
      max-width: initial;
    }
  }

  &__tabs-wrapper {
    margin-top: utils.toRem(40px);
  }
}

.rebate-section {
  display: flex;
  flex-direction: column;
  gap: utils.toRem(32px);
  margin-top: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    gap: utils.toRem(40px);
    margin-top: utils.toRem(40px);
  }

  &__pagination-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__cheques-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__header-wrapper {
    display: flex;
    gap: utils.toRem(16px);
    flex-direction: column;
    align-items: start;

    @include breakpoints.query('>=md') {
      gap: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__legend-wrapper {
    @include breakpoints.query('>=md') {
      margin-bottom: 0;
      margin-right: utils.toRem(16px);
    }
  }

  &__download-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__download-button {
    @include typekit.font-body-1;
  }
}
