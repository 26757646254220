@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.dam {
  &__title {
    @include typekit.font-subtitle-2-bold;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
    }
  }

  &__text-body {
    @include typekit.font-body-2;
    margin-top: utils.toRem(16px);
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__button {
    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }
}
