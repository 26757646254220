@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.row {
  display: none;

  @include breakpoints.query('>=lg') {
    display: table-row;
  }
}

.row-mobile {
  border: variables.$standard-border;

  @include breakpoints.query('>=lg') {
    display: none;
  }

  &:not(:first-child) {
    border-top: none;
  }

  &--opened {
    background-color: colors.$surface-color-variant;
  }
}

.cell {
  @include typekit.font-body-2;
  padding: utils.toRem(16px);
  color: colors.$surface-color-active;

  &__toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: 4px solid transparent;
    transition: outline-color 300ms ease-in-out;

    &:focus {
      outline-color: colors.$secondary-color-400;
    }

    &--opened {
      transform: rotate(180deg);
    }
  }

  &__icon {
    width: utils.toRem(24px);
  }

  &--text-left {
    text-align: left;
  }

  &--text-right {
    text-align: right;
  }

  &--text-center {
    text-align: center;
  }
}

.content-row,
.content-cell {
  padding: 0;
}

.content-cell {
  &__wrapper {
    width: 100%;

    &--initial {
      display: none;
    }
  }
}

.row,
.content-row--expanded {
  border:  variables.$standard-border;
}

.mobile-toggle-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
