@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.my-preferred-retailer {
  padding-top: utils.toRem(24px);
  padding-bottom: utils.toRem(24px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(32px);
  }

  @include breakpoints.query('>=lg') {
    padding-bottom: 0;
  }
}

.top-section {
  margin-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__heading {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__button-wrapper {
    &--no-results {
      @include breakpoints.query('<md') {
        display: none;
      }
    }
  }
}

.no-results {
  background-color: colors.$surface-color-variant;
  padding: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    padding: utils.toRem(24px);
    text-align: center;
  }

  &__heading {
    @include typekit.font-subtitle-1;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=md') {
      width: auto;
    }
  }
}

.results {
  &__retailer {
    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }
}
