@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.image-content {
  width: 100%;
  max-width: utils.toRem(1440px);

  @include breakpoints.query('>=md') {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__children {
    flex-grow: 1;
  }
}

.art-wrapper {
  display: none;
  flex-basis: 49.2%;
  position: relative;

  @include breakpoints.query('>=lg') {
    display: block;
  }

  &__deco {
    position: absolute;
    left: -3.81%;
    top: 0;
    bottom: 0;
  }

  &__line {
    height: 100%;

    &--anciliary-100 {
      stroke: colors.$anciliary-color-100;
    }
  }

  &__triangle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 50%;
    width: 10.88%;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);

    &--anciliary-100 {
      background-color: colors.$anciliary-color-100;
    }
  }

  &__shape-mould {
    display: flex;
    width: 100%;
    clip-path: polygon(10.875% 0, 100% 0, 100% 100%, 0% 100%);
  }

  &__image {
    width: 100%;
  }
}
