@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.footer {
  position: relative;
  background-color: colors.$secondary-color-900;

  &--hidden {
    display: none;
  }

  &__top-section {
    @include layout.container-md;
    padding-top: utils.toRem(24px);
    padding-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      display: flex;
      padding-top: utils.toRem(64px);
      padding-bottom: utils.toRem(40px);
    }
  }

  &__logos {
    display: flex;
    gap: utils.toRem(24px);
    margin-bottom: utils.toRem(32px);
    justify-content: space-between;
    align-items: center;

    @include breakpoints.query('>=md') {
      flex-direction: column;
      justify-content: revert;
      gap: utils.toRem(40px);
    }
  }

  &__logo-wrapper {
    display: flex;
    height: fit-content;
    width: fit-content;

    @include breakpoints.query('>=md') {
      margin-right: utils.toRem(88px);
    }

    &:hover::before,
    &:active::before,
    &:focus::before {
      background-color: transparent;
      border-color: transparent;
    }

    &:focus::before {
      outline: utils.toRem(4px) solid colors.$secondary-color-400;
    }
  }

  &__my-bayer-logo {
    width: utils.toRem(150px);
  }

  &__bayer-logo {
    width: utils.toRem(80px);

    @include breakpoints.query('>=md') {
      width: utils.toRem(100px);
    }
  }

  &__top-links-wrapper {
    margin-top: utils.toRem(20px);

    @include breakpoints.query('>=md') {
      margin-top: 0;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: utils.toRem(88px);
    }
  }

  &__links-column {
    &:not(:last-child) {
      margin-bottom: utils.toRem(48px);

      @include breakpoints.query('>=md') {
        margin-bottom: 0;
      }
    }
  }

  &__nav-title {
    @include typekit.font-overline;
    color: colors.$white;
    margin-bottom: utils.toRem(16px);
  }

  &__nav-item {
    @include typekit.font-subtitle-2;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }

    &--support {
      &:not(:last-child) {
        margin-bottom: utils.toRem(26px);

        @include breakpoints.query('>=md') {
          margin-bottom: utils.toRem(24px);
        }
      }
    }
  }

  &__link-title {
    @include typekit.font-caption;
    color: colors.$surface-color-variant;
    margin-bottom: utils.toRem(6px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(4px);
    }
  }

  &__bottom-section {
    background-color: colors.$secondary-color-700;
    clip-path: polygon(0 6.25%, 100% 0, 100% 100%, 0% 100%);

    @include breakpoints.query('>=md') {
      clip-path: polygon(0 55.5%, 100% 0, 100% 100%, 0% 100%);
    }
  }

  &__bottom-inner {
    @include layout.container-md;
    padding-top: utils.toRem(51px);
    padding-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      display: grid;
      grid-template-columns: 1fr 2fr;
      align-items: center;
      padding-top: utils.toRem(95px);
      padding-bottom: utils.toRem(24px);
    }

    @include breakpoints.query('>=lg') {
      grid-template-columns: 1fr 1.5fr 1fr;
    }
  }

  &__bottom-copyright-wrapper {
    @include breakpoints.query('>=md') {
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__bottom-links-wrapper {
    @include breakpoints.query('>=md') {
      grid-column: 2;
      grid-row: 1;
    }
  }

  &__copyright {
    @include typekit.font-caption;
    color: colors.$white;
  }

  &__bottom-nav {
    padding-bottom: utils.toRem(92px);

    @include breakpoints.query('>=md') {
      padding-bottom: 0;
    }
  }

  &__bottom-list {
    @include breakpoints.query('>=md') {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__bottom-nav-item {
    @include typekit.font-caption;

    @include breakpoints.query('>=lg') {
      flex-shrink: 0;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);

      @include breakpoints.query('>=md') {
        margin-bottom: 0;
        padding-right: utils.toRem(10px);
        position: relative;
      }

      &:after {
        @include breakpoints.query('>=md') {
          content: '/';
          color: colors.$white;
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  &__bottom-link {
    @include typekit.font-caption;
  }

  &__top-button {
    @include typekit.font-caption;
    margin: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: colors.$primary-color-400;
    color: colors.$white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: utils.toRem(12px) utils.toRem(20px) utils.toRem(12px) utils.toRem(30px);
    clip-path: polygon(14% 0, 100% 0, 100% 100%, 0% 100%);
    cursor: pointer;
  }

  &__top-icon {
    width: utils.toRem(18px);
    margin-bottom: 2px;
    fill: colors.$white;
  }

  &__top-text {
    display: inline-block;
  }
}
