@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.linear-chart {
  width: 100%;
  height: utils.toRem(600px);

  @include breakpoints.query('>=md') {
    height: utils.toRem(500px);
  }

  &__label {
    @include typekit.font-subtitle-1-bold;
    fill: colors.$surface-color-inactive-med;
  }

  &__wrapper > :global(.recharts-surface) {
    padding-inline: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      padding-inline: utils.toRem(32px);
    }
  }
}
