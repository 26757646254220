@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.contact-us {
  background-color: colors.$surface-color-variant;

  &__inner {
    @include layout.container-md;
    display: flex;
    padding-top: utils.toRem(32px);
    padding-bottom: utils.toRem(32px);
    flex-direction: column;

    @include breakpoints.query('>=lg') {
      flex-direction: row;
      justify-content: space-between;
      padding-top: utils.toRem(80px);
      padding-bottom: utils.toRem(80px);
      gap: utils.toRem(24px);
    }
  }

  &__support-description {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=lg') {
      flex-shrink: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    max-width: utils.toRem(418px);
  }

  &__title {
    @include typekit.font-display-6;
    padding-bottom: utils.toRem(8px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=lg') {
      @include typekit.font-display-5;
      padding-bottom: utils.toRem(16px);
    }
  }

  &__subtitle {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=lg') {
      @include typekit.font-subtitle-1;
    }
  }

  &__phone-information {
    display: none;

    @include breakpoints.query('>=lg') {
      display: flex;
      flex-direction: column;
      padding-top: utils.toRem(75px);
    }
  }

  &__phone {
    @include breakpoints.query('>=lg') {
      &:nth-child(2) {
        margin-top: utils.toRem(24px);
      }
    }
  }

  &__phone-number {
    @include typekit.font-body-1;
    text-decoration: none;
    margin-left: utils.toRem(-3px);
    color: colors.$surface-color-active;
    margin-top: utils.toRem(8px);
  }

  &__phone-title {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
  }

  &__line {
    @include hero.hero-line-divider;
    border: none;
    height: 1px;
    background-color: colors.$surface-color-stroke;

    @include breakpoints.query('>=md') {
      // Media query used for specificity overriding
      margin: utils.toRem(32px) 0;
    }

    @include breakpoints.query('>=lg') {
      display: none;
    }
  }

  &__support {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=lg') {
      flex-grow: 1;
      max-width: 50%;
    }
  }

  &__link-container {
    padding: utils.toRem(16px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__user-wrapper {
    display: flex;
    flex-direction: column;
    padding-block: utils.toRem(16px);

    @include breakpoints.query('>=lg') {
      flex-direction: row;
      padding-bottom: utils.toRem(32px);
    }

    &:first-child {
      padding-top: utils.toRem(0px);

      @include breakpoints.query('>=lg') {
        padding-top: utils.toRem(16px);
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    @include breakpoints.query('>=lg') {
      width: calc(50% - #{utils.toRem(32px)});
      flex-shrink: 0;
    }
  }

  &__image {
    border-radius: 50%;
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    flex-shrink: 0;
  }

  &__user-title {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(2px);

    @include breakpoints.query('>=xl') {
      @include typekit.font-subtitle-1;
    }
  }

  &__user-subtitle {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;

    &--lowercase {
      text-transform: none;
    }
  }

  &__support-line {
    display: none;

    @include breakpoints.query('>=lg') {
      margin: 0 utils.toRem(32px) 0 0;
      display: block;
      height: 100%;
      width: 1px;
      border: none;
      background-color: colors.$surface-color-stroke;
    }
  }

  &__user-details {
    display: flex;
    flex-direction: column;
    margin-top: utils.toRem(8px);
  }

  &__user-detail {
    @include typekit.font-subtitle-2;
    display: flex;
    align-items: center;
    color: colors.$surface-color-active;
    word-break: break-word;
    text-decoration: none;

    @include breakpoints.query('>=lg') {
      @include typekit.font-body-1;
      overflow-wrap: normal;
    }

    &:last-child {
      margin-top: utils.toRem(4px);
    }

    > span {
      display: flex;
      align-items: center;
    }
  }

  &__icon {
    width: utils.toRem(18px);
    height: utils.toRem(18px);
    fill: colors.$secondary-color-700;
    margin-right: utils.toRem(9px);
    flex-shrink: 0;

    @include breakpoints.query('>=lg') {
      align-self: flex-start;
      margin-top: utils.toRem(6px);
    }
  }
}
