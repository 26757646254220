@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.chart-legends {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: utils.toRem(48px);
  padding-block: utils.toRem(24px);
  border-top: variables.$standard-border;
  gap: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    gap: 0;
  }

  &__legend-wrapper {
    padding-inline: utils.toRem(12px);
    display: inline-flex;
    align-items: center;
    gap: utils.toRem(8px);
  }

  &__legend-icon {
    width: utils.toRem(16px);
    height: utils.toRem(16px);
  }

  &__legend-text {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;
  }
}
