@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/breakpoints';

.skeleton {
  border: variables.$standard-border;

  &__header {
    background-color: colors.$surface-color-variant;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  &__retailer {
    display: flex;
    padding: utils.toRem(12px) utils.toRem(16px);
    gap: utils.toRem(16px);
    width: 100%;
  }

  &__retailer-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: utils.toRem(8px);
  }

  &__retailer-name {
    background-color: colors.$skeleton-mask;
    height: utils.toRem(20px);
    width: 60%;
    @include breakpoints.query('>=md') {
      width: 30%;
    }
  }

  &__retailer-location {
    background-color: colors.$skeleton-mask;
    height: utils.toRem(20px);
    width: 50%;
    @include breakpoints.query('>=md') {
      width: 20%;
    }
  }

  &__retailer-avatar {
    height: utils.toRem(40px);
    width: utils.toRem(40px);
    border-radius: 50%;
    flex-shrink: 0;
    background-color: colors.$skeleton-mask;
  }

  &__preorders {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(24px);
    padding: utils.toRem(24px);
  }
}
