@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

$progress-bar-background-color: rgba(0, 0, 0, 0.08);

@mixin hover-focus-state {
  border: 4px solid transparent;
  transition: border-color 300ms ease-in-out background-color 300ms ease-in-out;

  &:hover {
    background-color: colors.$secondary-color-state-01;
  }

  &:focus {
    background-color: colors.$secondary-color-state-01;
    border-color: colors.$secondary-color-400;
    outline: none;
  }
}

.stepper {
  position: relative;
}

.progress-bar {
  position: absolute;
  appearance: none; // Apple Safari, Edge and Google Chrome
  background-color: $progress-bar-background-color; /* Needed for Firefox */
  border: none; /* Needed for Firefox */
  height: 4px;
  width: 100%;

  &::-webkit-progress-value {
    background-color: colors.$surface-color-active;
    transition: width 300ms linear;
  }

  &::-moz-progress-bar {
    background-color: colors.$surface-color-active;
    transition: width 300ms linear;
  }

  &::-webkit-progress-bar {
    background-color: $progress-bar-background-color;
  }
}

.mobile-menu {
  display: block;

  @include breakpoints.query('>=lg') {
    display: none;
  }

  &__toggle {
    @include hover-focus-state;
    display: flex;
    justify-content: center;
    align-items: center;

    &--menu-open {
      transform: rotate(180deg);
    }
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$surface-color-active;
  }
}

.steps-container {
  background-color: colors.$white;
  width: 100%;
  height: calc(100vh - #{utils.toRem(110px)}); // header: 53px, mobile menu: 57px
  position: absolute;
  padding: utils.toRem(16px);
  display: none;
  flex-direction: column;
  z-index: variables.$z-index-steps-container;

  @include breakpoints.query('>=lg') {
    display: flex;
    height: 100%;
    position: initial;
  }

  &--menu-open {
    display: flex;
  }

  &__list {
    flex: 1;
  }
}

.step-wrapper {
  @include breakpoints.query('>=lg') {
    margin-bottom: utils.toRem(12px);
  }
}

.step {
  display: flex;
  align-items: center;
  padding: utils.toRem(12px) 0;
  @include hover-focus-state;
  cursor: pointer;
  width: 100%;

  &__number {
    @include typekit.font-subtitle-1-bold;
    border: 1px solid colors.$disabled-color-stroke;
    flex: 0 0 auto;
    width: utils.toRem(32px);
    height: utils.toRem(32px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: utils.toRem(24px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &__label {
    @include typekit.font-subtitle-1-bold;
    color: colors.$surface-color-active;
    flex: 1;
    text-align: left;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-6;
    }
  }

  &--parent {
    &:focus {
      border-color: transparent;
    }
  }

  &--upcoming {
    pointer-events: none;

    .step__number {
      color: colors.$surface-color-inactive-med;
    }

    .step__label {
      color: colors.$surface-color-inactive-med;
    }
  }

  &--completed {
    .step__number {
      color: colors.$surface-color-active;
    }

    .step__label {
      color: colors.$surface-color-active;
    }
  }

  &--active {
    .step__number {
      background-color: colors.$surface-color-active;
      color: colors.$white;
    }

    .step__label {
      color: colors.$surface-color-active;
    }
  }

  &__icon {
    flex: 0 0 auto;
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-right: utils.toRem(16px);

    svg {
      fill: colors.$success-green;
    }
  }

  &--on-menu {
    border-bottom: variables.$standard-border;
    padding-left: utils.toRem(16px);
    padding-right: utils.toRem(16px);

    &:hover {
      background-color: colors.$white;
    }
  }
}

.sub-steps-container {
  display: none;
  flex-basis: 100%;

  &--level-1 {
    .sub-step {
      &__label {
        padding-top: utils.toRem(16px);
        padding-bottom: utils.toRem(16px);

        @include breakpoints.query('>=md') {
          padding-top: utils.toRem(12px);
          padding-bottom: utils.toRem(12px);
        }
      }
    }

    .sub-step__label {
      @include typekit.font-body-2;
      padding-left: utils.toRem(76px);

      @include breakpoints.query('>=md') {
        @include typekit.font-subtitle-1;
      }
    }
  }

  &--opened {
    display: block;
  }
}

.sub-step {
  &__label {
    @include typekit.font-subtitle-2-bold;
    @include hover-focus-state;
    color: colors.$surface-color-active;
    padding-top: utils.toRem(16px);
    padding-bottom: utils.toRem(16px);
    padding-left: utils.toRem(56px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    width: 100%;

    &--parent {
      &:focus {
        border-color: transparent;
      }
    }

    &--completed {
      color: colors.$surface-color-active;
    }

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1-bold;
      padding-top: utils.toRem(20px);
      padding-bottom: utils.toRem(20px);
      padding-left: utils.toRem(56px);
    }
  }

  &--upcoming {
    pointer-events: none;

    .sub-step__number {
      color: colors.$surface-color-inactive-med;
    }

    .sub-step__label {
      color: colors.$surface-color-inactive-med;
    }
  }

  &--active {
    position: relative;
    background-color: colors.$secondary-color-state-02;

    > .sub-step__label {
      color: colors.$surface-color-active;
    }

    &:hover,
    &:focus {
      border-left: none;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      background-color: colors.$secondary-color-400;
      height: 100%;
    }
  }

  &--with-children {
    background-color: colors.$white;

    &:before {
      display: none;
    }
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    margin-inline: utils.toRem(16px);
    flex-shrink: 0;

    svg {
      fill: colors.$success-green;
    }
  }
}

.stepper-footer {
  display: none;
  flex-shrink: 0;

  @include breakpoints.query('>=md') {
    display: block;
  }

  &--menu-open {
    display: block;
  }
}
