@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.retreat-section {
  padding-block: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    margin-block: utils.toRem(48px);
  }

  &__skeleton {
    @include layout.container-md;
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: colors.$white;
    border: variables.$standard-border;

    @include breakpoints.query('>=md') {
      width: math.percentage(math.div(564px, 1024px));
    }
  }

  &__text-wrapper {
    width: 100%;
    padding: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      padding: utils.toRem(32px);
    }

    &--widget {
      padding: utils.toRem(16px) 0;
    }
  }

  &__heading {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);

    &--bold {
      font-weight: bold;
    }
  }

  &__subtitle {
    @include typekit.font-subtitle-2;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-body-2;
    }

    &--active {
      color: colors.$surface-color-active;
    }

    &--bold {
      @include breakpoints.query('>=md') {
        @include typekit.font-body-2-bold;
      }
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    border: none;
    background-color: colors.$surface-color-stroke;
    margin: utils.toRem(24px) 0;
  }

  &__contact {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: utils.toRem(16px);
    background-color: colors.$surface-color-variant;
    border-top: variables.$standard-border;
    text-align: start;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(16px) utils.toRem(32px);
    }
  }

  &__hr {
    @include layout.page-section-divider;
  }
}

.retreat-section-widget {
  &__inner {
    height: 100%;
    padding-top: utils.toRem(24px);
    border-top: variables.$standard-border;
  }

  &__title {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__subtitle {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-top: utils.toRem(16px);
  }

  &__progress-bar-wrapper {
    margin-top: utils.toRem(16px);
  }

  &__progress-bar-subtitle {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
  }

  &__button {
    padding-top: utils.toRem(16px);
  }
}
