@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/base/animations';

.settings-wrapper {
  &__title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: utils.toRem(24px);
    border-bottom: variables.$standard-border;
  }

  &__heading {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
  }

  &__footer {
    position: relative;
    margin-top: utils.toRem(16px);
    padding-top: utils.toRem(9px);
    padding-bottom: utils.toRem(16px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: utils.toRem(-16px);
      right: utils.toRem(-16px);
      height: 1px;
      background-color: colors.$surface-color-stroke;

      @include breakpoints.query('>=md') {
        left: utils.toRem(-32px);
        right: utils.toRem(-32px);
      }
    }
  }

  &__button {
    width: 100%;

    @include breakpoints.query('>=lg') {
      width: auto;
    }
  }

  &__loading-icon {
    @include animations.spinner-rotate-left-animation;
  }
}
