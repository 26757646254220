@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.questions-table {

  &__heading {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin: utils.toRem(32px) 0 utils.toRem(24px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
      margin: utils.toRem(48px) 0 utils.toRem(40px);
    }
  }

  &__content {
    white-space: pre-line;
    
  }
}
