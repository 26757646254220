@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.landing-hero {
  background-color: colors.$surface-color-active;
  padding: utils.toRem(40px) 0;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(64px) 0;
  }

  &__wrapper {
    @include layout.container-md;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      min-height: utils.toRem(144px);
    }
  }

  &__text {
    @include hero.hero-text;
  }

  &__welcome-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-basis: 50%;
      flex-grow: 1;
      justify-content: center;
    }
  }

  &__line {
    @include hero.hero-line-divider;
    flex-shrink: 0;
  }

  &__rewards-wrapper {
    display: flex;
    align-items: center;

    @include breakpoints.query('>=md') {
      flex-basis: 50%;
      flex-grow: 1;
    }
  }
}
