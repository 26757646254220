@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/layout';

.hero-skeleton {
  background-color: colors.$surface-color-active;

  &__wrapper {
    @include layout.container-md;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      min-height: utils.toRem(144px);
    }
  }

  &__text {
    @include hero.hero-text;
  }

  &__welcome-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-grow: 1;
      justify-content: center;
    }
  }

  &__line {
    @include hero.hero-line-divider;
  }

  &__rewards-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-grow: 1;
      justify-content: center;
      align-items: center;
    }
  }

  &__loading {
    background-color: colors.$loading-color-state;

    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }

    &--heading {
      width: math.percentage(math.div(180px, 495.5px));
      height: utils.toRem(40px);
    }

    &--subtitle-right {
      width: math.percentage(math.div(463.5px, 495.5px));
      height: utils.toRem(24px);
    }
  }
}
