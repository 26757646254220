@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';

.skeleton {
  padding: utils.toRem(24px) 0;

  &__hr {
    margin-top: utils.toRem(24px);
    margin-bottom: utils.toRem(24px);
    border: none;
    height: 1px;
    background-color: colors.$surface-color-stroke;
  }

  &__section {
    width: 100%;
    height: utils.toRem(80px);
    background-color: colors.$skeleton-mask;
  }

  &__text {
    width: utils.toRem(200px);
    height: utils.toRem(20px);
    background-color: colors.$skeleton-mask;
    margin-top: utils.toRem(16px);

    &:not(:last-child) {
      margin-bottom: utils.toRem(24px);
    }
  }

  &__footer {
    position: relative;
    padding-top: utils.toRem(9px);
    padding-bottom: utils.toRem(16px);

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: utils.toRem(-16px);
      right: utils.toRem(-16px);
      height: 1px;
      background-color: colors.$surface-color-stroke;

      @include breakpoints.query('>=md') {
        left: utils.toRem(-32px);
        right: utils.toRem(-32px);
      }
    }
  }

  &__button {
    width: 100%;
  }
}
