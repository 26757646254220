@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.preferred-retailers {
  &__label {
    @include typekit.font-subtitle-1;
    margin-bottom: utils.toRem(24px);
    color: colors.$surface-color-active;

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__retailers-wrapper {
    margin-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      margin-bottom: utils.toRem(32px);
    }
  }

  &__retailer {
    &:not(:last-child) {
      margin-bottom: utils.toRem(16px);
    }
  }

  &__button {
    @include breakpoints.query('<md') {
      width: 100%;
    }
  }
}
