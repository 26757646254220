@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.filter-modal {
  &__footer,
  &__header {
    padding-inline: utils.toRem(16px);
  }

  &__section {
    border-top: variables.$standard-border;
    padding: utils.toRem(24px);
  }

  &__checkbox-container {
    list-style: none;
  }

  &__checkbox {
    text-transform: capitalize;
  }

  &__radio {
    padding-inline: 0px;
  }

  &__label {
    @include typekit.font-body-1-bold;
    color: colors.$secondary-color-1000;
    margin-bottom: utils.toRem(16px);
  }
}
