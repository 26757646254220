@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/hero';
@use 'src/assets/styles/base/typekit';

.rewards-section {
  display: flex;
  flex-direction: column;

  @include breakpoints.query('>=md') {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }

  &__text {
    @include hero.hero-text;
  }

  &__text-with-icon {
    @include breakpoints.query('>=md') {
      display: flex;
    }
  }

  &__today-icon {
    display: none;

    @include breakpoints.query('>=lg') {
      display: block;
      @include hero.hero-subtitle-icon;
    }
  }

  &__span {
    display: block;
    width: 100%;
    text-align: start;

    @include breakpoints.query('>=md') {
      display: inline-block;
      width: auto;
    }
  }

  &__span-wrapper {
    flex-grow: 1;
  }

  &__no-access {
    display: block;
    width: 100%;
    text-align: start;

    @include breakpoints.query('>=md') {
      text-align: center;
    }

    &:first-of-type {
      margin-bottom: utils.toRem(16px);

      @include breakpoints.query('>=md') {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    @include typekit.font-subtitle-2;
    color: colors.$white;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }

    &:hover,
    &:focus {
      color: colors.$surface-color-active;
    }
  }

  &__no-rewards-title {
    display: inline-block;
    margin-bottom: utils.toRem(16px);
  }
}
