@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.setup-sidebar {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include breakpoints.query('>=lg') {
    width: utils.toRem(390px);
    top: utils.toRem(80px); // Positioned at the end of the header
    position: sticky;
    height: fit-content;
  }

  @include breakpoints.query('>=md') {
    top: utils.toRem(56px); // Positioned at the end of the header
  }

  &__stepper {
    flex: 1;
  }
}

.carousel-cta {
  padding: utils.toRem(32px) utils.toRem(30px);
  background-color: colors.$secondary-color-state-01;
  display: none;

  @include breakpoints.query('>=lg') {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__image {
    margin-right: utils.toRem(32px);
    width: utils.toRem(150px);
    height: utils.toRem(149px);
  }

  &__text {
    @include typekit.font-subtitle-2;
    color: rgba($color: colors.$surface-color-active, $alpha: 0.8);
  }

  &__link {
    @include typekit.font-body-1;
    display: block;
    margin-top: utils.toRem(6px);
  }
}

.save-progress {
  text-align: center;
  padding: utils.toRem(6px) 0;

  @include breakpoints.query('>=md') {
    padding: utils.toRem(8px) 0;
  }

  &__button {
    @include breakpoints.query('>=md') {
      @include typekit.font-body-1;
    }
  }
}
