@use 'src/assets/styles/base/layout';

.other-programs {
  display: block;
  width: 100%;

  &__divider {
    @include layout.page-section-divider;
  }
}
