@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.spinner {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__icon {
    animation-name: spinner-rotate-right;
    animation-duration: 833ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    &--md {
      width: utils.toRem(64px);
      height: utils.toRem(64px);
    }

    &--lg {
      width: utils.toRem(96px);
      height: utils.toRem(96px);
    }
  }

  &__text {
    @include typekit.font-body-2-bold;
    text-align: center;
    color: colors.$surface-color-active;
    margin-top: utils.toRem(16px);
  }
}
