@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  &-retailer {
    display: flex;
    align-items: center;
    border: variables.$standard-border;
    padding: utils.toRem(12px) utils.toRem(16px);
    margin: utils.toRem(16px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(20px) utils.toRem(24px) utils.toRem(20px) utils.toRem(16px);
    }
  }

  &__field {
    height: utils.toRem(56px);
    background-color: colors.$surface-color-stroke;

    &--large {
      width: 100%;
    }

    margin: utils.toRem(24px) 0;
  }

  &__avatar {
    width: utils.toRem(40px);
    height: utils.toRem(40px);
    margin-right: utils.toRem(16px);
    border-radius: 50%;
    background-color: colors.$surface-color-stroke;
    flex-shrink: 0;
  }

  &__text-wrapper {
    flex-grow: 1;
  }

  &__text {
    height: utils.toRem(16px);
    background-color: colors.$surface-color-stroke;

    &--small {
      width: math.percentage(math.div(116px, 416px));
    }

    &--medium {
      width: math.percentage(math.div(166px, 416px));
    }

    &--large {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(8px);
    }
  }

  &__subheader-spacing {
    margin: utils.toRem(24px) 0;
  }

  &__hr {
    height: 1px;
    border: none;
    margin: utils.toRem(16px) 0;
    background-color: colors.$surface-color-stroke;
  }
}
