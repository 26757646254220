@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.date-range-filter-options {
  &__custom {
    @include typekit.font-subtitle-1;
    position: relative;
    cursor: pointer;
    color: colors.$surface-color-active;
    display: flex;
    padding: utils.toRem(20px) utils.toRem(16px);
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: variables.$standard-border;
    outline: none;

    &:before {
      content: '';
      display: block;
      height: calc(100% - 8px);
      width: calc(100% - 8px);
      position: absolute;
      top: 0;
      left: 0;
      border: 4px solid transparent;
      transition: border-color 300ms ease-in-out;
    }

    &:focus {
      &:before {
        border-color: colors.$secondary-color-400;
      }
    }

    @include breakpoints.query('>=lg') {
      border-top: variables.$standard-border;
      border-bottom: none;
      border-right: none;
      border-left: none;
    }
  }

  &__custom-icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
    fill: colors.$secondary-color-700;
  }
}

.radio-buttons {
  padding: utils.toRem(20px) utils.toRem(16px);
}
