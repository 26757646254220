@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.breakdown {
  padding: utils.toRem(16px);

  @include breakpoints.query('>=md') {
    padding-top: utils.toRem(38px) utils.toRem(39px) utils.toRem(38px) utils.toRem(24px);
  }

  @include breakpoints.query('>=lg') {
    padding: utils.toRem(24px);
  }

  @include breakpoints.query('>=xl') {
    padding: utils.toRem(25px) utils.toRem(16px) utils.toRem(25px) utils.toRem(16px);
  }

  &--retreat {
    @include breakpoints.query('>=md') {
      padding-bottom: utils.toRem(24px);
    }

    @include breakpoints.query('>=xl') {
      padding-bottom: utils.toRem(21px);
    }
  }

  &__metrics-inner {
    padding-bottom: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      display: flex;
      align-items: center;
    }
  }

  &__donut-wrapper {
    padding-inline: utils.toRem(24px);
    max-width: utils.toRem(284px);
    margin: 0 auto;

    @include breakpoints.query('>=md') {
      max-width: utils.toRem(244px);
      margin: 0;
      margin-left: utils.toRem(-24px);
    }
  }

  &__information {
    @include breakpoints.query('>=md') {
      margin-left: utils.toRem(140px);
    }

    @include breakpoints.query('>=lg') {
      margin-left: utils.toRem(40px);
    }
  }

  &__metrics-heading {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
    }
  }

  &__cards-wrapper {
    margin-top: utils.toRem(32px);
  }

  &__metrics-title {
    @include typekit.font-overline;
    color: colors.$surface-color-inactive-med;
  }

  &__metrics-subtitle {
    @include typekit.font-display-4;
    color: colors.$surface-color-active;
    margin-top: utils.toRem(8px);
  }

  &__card {
    @include typekit.font-subtitle-2;
    margin-top: utils.toRem(16px);
  }

  &__program-card {
    align-items: center;
  }

  &__card-heading,
  &__card-description {
    @include typekit.font-subtitle-2-bold;
  }

  &__divider {
    display: none;
    border: none;

    @include breakpoints.query('>=md') {
      display: block;
      height: 1px;
      width: 100%;
      background-color: colors.$surface-color-stroke;
      margin-block: utils.toRem(24px);
    }
  }

  &__rewards {
    display: none;

    @include breakpoints.query('>=md') {
      display: block;
      margin-top: utils.toRem(24px);
      padding-inline: utils.toRem(8px);
    }
  }
}
