@use 'sass:math';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  display: flex;
  align-items: center;
  border: variables.$standard-border;
  padding: utils.toRem(12px) utils.toRem(16px);

  @include breakpoints.query('>=md') {
    padding: utils.toRem(20px) utils.toRem(24px) utils.toRem(20px) utils.toRem(16px);
  }

  &__text-wrapper {
    flex-grow: 1;
  }

  &__text {
    height: utils.toRem(16px);
    background-color: colors.$surface-color-stroke;

    &--small {
      width: math.percentage(math.div(116px, 416px));
    }

    &--medium {
      width: math.percentage(math.div(166px, 416px));
    }

    &--large {
      width: 100%;
    }

    &:not(:last-child) {
      margin-bottom: utils.toRem(8px);
    }
  }
}
