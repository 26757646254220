@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

.radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: utils.toRem(12px) utils.toRem(18px);
  cursor: pointer;

  &--disabled {
    pointer-events: none;
  }

  &__input,
  &__input:checked {
    appearance: none;
    opacity: 0;
    position: absolute;
  }

  &__input:disabled {
    pointer-events: none;
  }

  &__input:checked + &__circle {
    border-color: colors.$primary-color-400;

    &:after {
      content: '';
      width: utils.toRem(10px);
      height: utils.toRem(10px);
      background-color: colors.$primary-color-400;
      border-radius: 50%;
    }

    &--disabled {
      border-color: colors.$disabled-color;

      &:after {
        background-color: colors.$disabled-color;
      }
    }
  }

  &__circle-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: utils.toRem(40px);
    width: utils.toRem(40px);
    border-radius: utils.toRem(20px);
    border: variables.$inactive-border;
    background-color: transparent;
    transition: border-color 300ms ease-in-out background-color 300ms ease-in-out;

    &:hover {
      background-color: colors.$primary-color-state-01;
    }

    &:focus-within {
      background-color: colors.$primary-color-state-01;
      border-color: colors.$secondary-color-400;
    }

    &:active {
      background-color: colors.$primary-color-state-03;
    }
  }

  &__circle {
    min-width: utils.toRem(20px);
    width: utils.toRem(20px);
    height: utils.toRem(20px);
    border: 2px solid colors.$surface-color-inactive-med;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--disabled {
      border-color: colors.$disabled-color;

      &:after {
        background-color: colors.$disabled-color;
      }
    }
  }

  &__content {
    margin-left: utils.toRem(8px);
    overflow: hidden;
    max-width: 100%;
  }

  &__label {
    @include typekit.font-body-2;
    color: colors.$secondary-color-900;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__description {
    @include typekit.font-body-2;
    color: colors.$surface-color-inactive-med;
  }
}
