@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/animations';
@use 'src/assets/styles/base/typekit';

.order-product {
  @include typekit.font-subtitle-1;
  display: flex;
  flex-direction: column;
  border: variables.$standard-border;

  @include breakpoints.query('>=sm') {
    flex-direction: row;
    justify-content: space-between;
  }

  &__upper-section {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoints.query('>=md') {
      flex-direction: row;
      align-items: center;
      max-height: utils.toRem(130px);
    }
  }

  &__lower-section {
    height: auto;
    display: flex;
    align-items: center;
  }

  &__image-wrapper {
    width: utils.toRem(130px);
    height: utils.toRem(130px);
    padding: utils.toRem(8px);
    display: flex;
    justify-content: center;
    align-self: stretch;
    margin-top: utils.toRem(16px);
    margin-left: utils.toRem(16px);
    background-color: colors.$surface-color-variant;

    @include breakpoints.query('>=md') {
      height: auto;
      align-self: stretch;
      margin: 0;
    }
  }

  &__product-img {
    height: 100%;
  }

  &__info-container {
    @include typekit.font-subtitle-1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-grow: 1;
    overflow: hidden;
    box-sizing: border-box;
    padding: utils.toRem(8px) utils.toRem(16px) utils.toRem(20px) utils.toRem(16px);

    @include breakpoints.query('>=md') {
      padding: utils.toRem(16px);
    }
  }

  &__info-text {
    word-break: break-all;

    &--bold {
      @include typekit.font-subtitle-1-bold;
      color: colors.$surface-color-active;
    }

    &--light {
      color: colors.$surface-color-inactive-med;
    }
  }

  &__text-link {
    @include typekit.font-body-1;
    margin: 0 utils.toRem(16px) utils.toRem(36px) utils.toRem(16px);
    line-height: utils.toRem(20px);
    letter-spacing: utils.toRem(0.25px);
    border: none;

    @include breakpoints.query('>=md') {
      margin: 0 utils.toRem(16px);
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: utils.toRem(8px);
    width: 100%;
    padding-bottom: utils.toRem(16px);

    @include breakpoints.query('>=sm') {
      margin-right: utils.toRem(8px);
      flex-direction: column;
      justify-content: center;
      padding: 0;
      width: auto;
    }
  }

  &__icon-wrapper {
    border: none;

    &:hover {
      background-color: none;
    }
  }

  &__icon-button {
    height: utils.toRem(24px);
    width: utils.toRem(24px);
    flex-shrink: 0;

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }
}
