@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.doughnut-chart {
  position: relative;

  &__text-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: calc(100% - #{utils.toRem(56px)});
  }

  &__label {
    @include typekit.font-overline;
    margin-bottom: utils.toRem(8px);
    color: colors.$surface-color-inactive-med;
    text-transform: uppercase;
  }

  &__price {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
  }
}

.chart-tooltip {
  position: absolute;
  background-color: colors.$secondary-color-900;
  padding: utils.toRem(16px);
  opacity: 1;
  transition: opacity 300ms ease-in-out;
  pointer-events: none;

  &--hidden {
    opacity: 0;
  }

  &__text {
    @include typekit.font-subtitle-2;
    color: colors.$white;
  }
}
