@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';

.skeleton {
  width: 100%;
  height: utils.toRem(24px);
  background-color: colors.$skeleton-mask;

  &:not(:last-child) {
    margin-bottom: utils.toRem(16px);

    @include breakpoints.query('>=lg') {
      margin-bottom: 0;
    }
  }

  &__row {
    border: variables.$standard-border;

    &:not(:first-child) {
      border-top: none;
    }

    @include breakpoints.query('>=lg') {
      border: none;
    }
  }

  &__cell {
    width: 100%;
    padding: utils.toRem(14px) utils.toRem(16px);

    &--top {
      vertical-align: top;
      padding-left: 0;
    }
  }

  &__icon-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: utils.toRem(4px);
  }

  &__icon {
    width: utils.toRem(24px);
    height: utils.toRem(24px);
  }
}
