@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/layout';

.tabs-section {
  margin-bottom: utils.toRem(32px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(80px);
  }
}
