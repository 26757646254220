@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.enrolled-programs {
  &__wrapper {
    @include layout.container-md;
    padding-top: utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(32px);

    @include breakpoints.query('>=md') {
      padding-top: utils.toRem(32px);
    }
  }

  &__title {
    @include typekit.font-display-6;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(16px);
    display: block;

    @include breakpoints.query('>=md') {
      @include typekit.font-display-5;
    }
  }

  &__sub-title {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
    max-width: utils.toRem(577px);
    display: block;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
      margin-bottom: 0;
    }
  }

  &__card {
    border: variables.$standard-border;
    padding: utils.toRem(16px);
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      padding: utils.toRem(32px);
      gap: utils.toRem(24px);
    }
  }

  &__card-program {
    @include typekit.font-display-6;
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      gap: utils.toRem(24px);
      align-items: center;
      flex-direction: revert;
    }
  }

  &__card-description {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;

    @include breakpoints.query('>=md') {
      @include typekit.font-subtitle-1;
    }
  }

  &__card-button {
    @include breakpoints.query('>=md') {
      width: fit-content;
    }
  }

  &__programs {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    gap: utils.toRem(24px);

    @include breakpoints.query('>=md') {
      grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoints.query('>=lg') {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__program {
    display: flex;
    align-items: center;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(24px);
    }
  }

  &__program-icon {
    display: flex;
    align-content: center;
    margin-right: utils.toRem(16px);
  }

  &__program-text {
    @include typekit.font-body-1-bold;
    color: colors.$surface-color-active;
  }

  &__spinner-wrapper {
    border: variables.$standard-border;
    padding: utils.toRem(32px) 0;

    @include breakpoints.query('>=md') {
      padding: utils.toRem(78px) 0;
    }
  }
}
