@use 'colors';

// Breakpoints
$breakpoints: (
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1440px,
);

// Fonts
$font-size: 14px;
$font-size-base: 16px;
$font-family-base: Helvetica Neue;

// Sizes
$header-height-desktop: 4.5rem; // 72px
$header-height-mobile: 4rem; // 64px

// z-index
$z-index-modal: 700;
$z-index-modal-header: 10;
$z-index-modal-content: 9;
$z-index-modal-footer: 8;
$z-index-header: 100;
$z-index-secondary-nav: 90;
$z-index-steps-footer: 5;
$z-index-steps-container: 20;
$z-index-tooltip-wrapper: 4;
$z-index-tooltip: 10;
$z-index-snackbar: 800;

// Calendar
$calendar-width: 20.5rem; // 328px

// Border
$standard-border: 1px solid colors.$surface-color-stroke;
$inactive-border: 4px solid transparent;
$active-border: 4px solid colors.$secondary-color-400;
