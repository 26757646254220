@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';

@mixin pill-color($color, $background-color, $border-color) {
  color: $color;
  background-color: $background-color;
  border-color: $border-color;
}

.pill {
  position: relative;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  transition: background-color 300ms ease-in-out;

  &--sm {
    @include typekit.font-caption;
    font-weight: 700;
    padding: utils.toRem(2px) utils.toRem(6px);
    border-radius: utils.toRem(20px);
  }

  &--sm#{&}--clickable {
    &::before {
      border-radius: utils.toRem(20px);
    }
  }

  &--md {
    @include typekit.font-subtitle-2;
    font-weight: 700;
    padding: utils.toRem(6px) utils.toRem(12px);
    border-radius: utils.toRem(100px);
  }

  &--md#{&}--clickable {
    &::before {
      border-radius: utils.toRem(100px);
    }
  }

  &--blue {
    @include pill-color(
      $color: colors.$white,
      $background-color: colors.$surface-color-active,
      $border-color: colors.$surface-color-active
    );
  }

  &--blue#{&}--clickable {
    &:hover,
    &:focus {
      background-color: colors.$secondary-color-1000;
    }

    &:active {
      background-color: colors.$secondary-color-700;
    }
  }

  &--green {
    @include pill-color(
      $color: colors.$success-color-900,
      $background-color: colors.$success-color-100,
      $border-color: colors.$success-color-100
    );
  }

  &--red {
    @include pill-color(
      $color: colors.$error-color-900,
      $background-color: colors.$error-color-100,
      $border-color: colors.$error-color-100
    );
  }

  &--white {
    @include pill-color(
      $color: colors.$surface-color-active,
      $background-color: colors.$white,
      $border-color: colors.$disabled-color-stroke
    );
  }

  &--white#{&}--clickable {
    &:hover,
    &:focus {
      background-color: colors.$surface-color-variant;
    }

    &:active {
      background-color: colors.$surface-color-stroke;
    }
  }

  &--clickable {
    &::before {
      content: '';
      position: absolute;
      top: utils.toRem(-4px);
      right: utils.toRem(-4px);
      bottom: utils.toRem(-4px);
      left: utils.toRem(-4px);
      border: variables.$inactive-border;
      transition: border-color 300ms ease-in-out;
    }

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;

      &::before {
        border-color: colors.$secondary-color-400;
      }
    }
  }

  &__icon {
    fill: currentColor;

    &--sm {
      width: utils.toRem(12px);
      height: utils.toRem(12px);
      margin-right: utils.toRem(4px);
    }

    &--md {
      width: utils.toRem(16px);
      height: utils.toRem(16px);
      margin-right: utils.toRem(8px);
    }
  }
}
