@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/base/typekit';

.pre-order-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: utils.toRem(16px);
  margin-bottom: utils.toRem(24px);

  @include breakpoints.query('>=md') {
    margin-bottom: utils.toRem(40px);
    justify-content: left;
  }

  &__title {
    @include typekit.font-display-5;
    color: colors.$surface-color-active;
    margin-bottom: utils.toRem(8px);

    @include breakpoints.query('>=md') {
      @include typekit.font-display-4;
      margin-bottom: utils.toRem(16px);
    }
  }

  &__sub-title {
    @include typekit.font-body-1;
    color: colors.$surface-color-inactive-med;
    white-space: pre-wrap;
  }

  &__submitted-icon {
    height: utils.toRem(33px);
    width: utils.toRem(33px);
    margin-bottom: utils.toRem(20px);
    border-radius: 50%;
    background-color: colors.$success-green;
  }

  &__button {
    width: fit-content;
  }
}
