@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/layout';
@use 'src/assets/styles/base/typekit';

.account-settings {
  width: 100%;
  @include breakpoints.query('>=lg') {
    padding-top: utils.toRem(44px);
    padding-bottom: utils.toRem(170px);
  }

  &__container {
    @include breakpoints.query('>=lg') {
      @include layout.container-md;
    }
  }

  &__heading {
    @include typekit.font-display-4;
    color: colors.$surface-color-active;

    @include breakpoints.query('>=lg') {
      margin-bottom: utils.toRem(40px);
    }
  }

  &__content {
    @include breakpoints.query('>=lg') {
      display: flex;
      align-items: flex-start;
    }
  }

  &__navigation-wrapper {
    @include breakpoints.query('>=lg') {
      flex-shrink: 0;
      padding-right: utils.toRem(48px);
    }
  }

  &__outlet-wrapper {
    @include breakpoints.query('<lg') {
      @include layout.container-md;
    }

    @include breakpoints.query('>=lg') {
      flex-grow: 1;
      padding-left: utils.toRem(48px);
      border-left: variables.$standard-border;
    }
  }
}
