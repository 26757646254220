@use 'src/assets/styles/abstracts/colors';
@use 'src/assets/styles/abstracts/utils';
@use 'src/assets/styles/abstracts/variables';
@use 'src/assets/styles/base/typekit';
@use 'src/assets/styles/abstracts/breakpoints';
@use 'src/assets/styles/base/animations';

.order-container {
  border: variables.$standard-border;

  &__header {
    background-color: colors.$surface-color-variant;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoints.query('>=md') {
      flex-direction: row;
    }
  }

  &__retailer {
    display: flex;
    padding: utils.toRem(12px) utils.toRem(16px);
    gap: utils.toRem(16px);
  }

  &__retailer-info {
    @include typekit.font-body-1;
    word-break: break-all;
    display: flex;
    flex-direction: column;
  }

  &__retailer-name {
    color: colors.$surface-color-active;
  }

  &__retailer-location {
    color: colors.$surface-color-inactive-med;
  }

  &__retailer-avatar {
    margin: utils.toRem(8px) 0;
  }

  &__submit-order {
    margin: 0 utils.toRem(16px) utils.toRem(16px) utils.toRem(16px);

    @include breakpoints.query('>=md') {
      margin: utils.toRem(22px) utils.toRem(24px);
    }

    &--loading {
      @include animations.spinner-rotate-left-animation;
    }
  }

  &__pre-orders {
    display: flex;
    flex-direction: column;
    gap: utils.toRem(8px);
    padding: utils.toRem(16px);

    @include breakpoints.query('>=md') {
      gap: utils.toRem(24px);
      padding: utils.toRem(24px);
    }
  }
}
